import React, { createContext, useContext, useEffect, useState } from 'react';
import { Context as ContextStore } from '../../../contexts/store';
import { Context as  ContextCart } from '../../../contexts/cart';
import cart from '../../../assets/icons/cart.png';
import { 
    Drawer, 
    DrawerBody, 
    DrawerContent, 
    DrawerOverlay, 
    Spinner,
    useMediaQuery,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Button,
    Input
} from '@chakra-ui/react';
import useWindowDimensions from 'hooks/sizeWindow';
import "../style.scss";
import { useCustomToast } from "hooks/useToast";
import { formattingNumberWithDecimals, formattingNumberWithoutDecimals } from 'utils';
import plus from 'assets/icons/plus.png';
import minus from 'assets/icons/minus.png';
import delete_icon from 'assets/icons/delete_icon.png';
import close from 'assets/icons/close.png';
import api from 'services/api';
import { useAuth } from 'hooks/useAuth';
import empty from 'assets/icons/empty-cart.png';
import happy from 'assets/icons/happy.png';
import TimerCount from './timer';
import { useNavigate } from 'react-router-dom';

const CartButton = ({ children }) => {
    const navigate = useNavigate();
    const { dataUser } = useAuth();
    const [stateStore, dispatchStore] = useContext(ContextStore);
    const [stateCart, dispatchCart] = useContext(ContextCart);
    const [quantItemsCart, setQuantItemsCart] = useState(0);
    const [isMobileSize] = useMediaQuery("(max-width: 992px)");
    const { height, width } = useWindowDimensions();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showCart, setShowCart] = useState(true);
    const [newValueQuantity, setNewValueQuantity] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentEan, setCurrentEan] = useState(0);
    const [loadingActive, setLoadingActive] = useState(false);
    const [carrinhoValido, setCarrinhoValido] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [sucessActive, setSucessActive] = useState(false);
    const [totalPontos, setTotalPontos] = useState('...');
    const [products, setProducts] = useState([]);
    const [productsActiveded, setProductsActiveded] = useState([]);
    const [dateExpire, setDateExpire] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModalInput, setShowModalInput] = useState(false);
    
    const { getToast } = useCustomToast();
  
    useEffect(() => {
        verifyHaveCartActive()
    }, [])

    useEffect(() => {
        setQuantItemsCart(stateStore?.quantidade)
    }, [stateStore]);

    useEffect(() => {
        setSidebarOpen(stateCart.isShow) 
    }, [stateCart]);

    useEffect(() => {
        if(sidebarOpen){
            let newData = []
            
            stateStore.products.map(item => 
                newData.push({...item, selected: false, loading: false})  
            )

            let newTotal = 0
            newData.map((item) => newTotal = newTotal + (item.pontosUnitario * item.quantidade))
            setTotalPontos(newTotal.toString())
            setProducts(newData)
        }

    }, [sidebarOpen])

    const verifyHaveCart = async () => {
        const { data } = await api.get("/motorDePontos/carrinho");
        if(data?.produtos?.length > 0){
            let newData = []
            data.produtos.map(item => 
                newData.push({...item, selected: false, loading: false})  
            )
            setLoading(false)
            setProducts(newData)
            setTotalPontos(data.totalPontos)
            dispatchStore({ type: "UPDATE_QUANT_CARRINHO", quantidade: data.produtos.length, products: data.produtos})
            dispatchCart({ type: "CHANGE_SHOW_CART", isShow: false})
        }
        setLoading(false)
    }

    const verifyHaveCartActive = async () => {
        let { data } = await api.get("/motorDePontos/consultarCarrinhoAtivo");
        verifyHaveCart()
        /*
        if(data.length == 0){
            verifyHaveCart()
        }else{
            dispatchStore({ type: "UPDATE_QUANT_CARRINHO", quantidade: 0, products: []})
            var dateExpire = new Date(data[0].dataValidade);
            dateExpire.setHours(dateExpire.getHours() -  3);
            setLoading(false)
            setDateExpire(dateExpire)
            setProductsActiveded(data)
            dispatchCart({ type: "CHANGE_SHOW_CART", isShow: false, dateExpire})
        }
        */
    }

    const confirmActiveDiscount = async () => {
        setSidebarOpen(false)
        navigate('/carrinho')

        /*
        setLoadingActive(true)

        if(!carrinhoValido) {
            getToast({
                messageError: errorMessage,
                status: "error",
                position: isMobileSize ? "bottom" : "top-left"
            });
            return 
        }

        try {
            const {data} = await api.get('/motorDePontos/ativarCarrinho');
            const hours = calcularTempoRestante(data[0].dataValidade).horas
            const minutes = calcularTempoRestante(data[0].dataValidade).minutos

            getToast({
                messageError: `Seu desconto foi ativado com sucesso!`,
                status: "success",
                position: isMobileSize ? "bottom" : "top-left"
            });

            setDateExpire(data[0].dataValidade)
            setSucessActive(true)
            setProducts([])
            dispatchStore({ 
                type: "UPDATE_QUANT_CARRINHO", 
                quantidade: 0, 
                products: []
            })
        } catch (e) {
            getToast({
                messageError: e.response.data.errors[0].message,
                status: "error",
                position: isMobileSize ? "bottom" : "top-left"
            });
        } finally {
            setLoadingActive(false)
        }
        */
    }

    const excludeProduct = async (ean, i) => {

        const data = products.map((item, index) => {
            if (index === i) {
              return {...item, loading: true};
            }
            return item;
        });
        setProducts(data)

        let dataPost = {
            documento: dataUser.documento,
            ean: ean,
            quantidade: 0
        }
    
        try {
            await api.post('/motorDePontos/carrinho', dataPost);
            let data = products.filter(item => (item.ean != ean));

            setProducts(data) 

           
            let newTotal = 0
            data.map((item) => newTotal = newTotal + (item.pontosUnitario * item.quantidade))
            setTotalPontos(newTotal.toString())
    
            const newData = data.map((item, index) => {
                if (index === i) {
                  return {...item, loading: false};
                }
                return item;
            });

            dispatchStore({ 
            type: "UPDATE_QUANT_CARRINHO", 
                quantidade: data.length, 
                products: newData
            })
    
                
            if(newData.length == 0){
                setQuantItemsCart(0)
                dispatchStore({ type: "UPDATE_QUANT_CARRINHO", quantidade: 0, products: []})
            }
            setProducts(newData)

            getToast({
                messageError: 'Carrinho atualizado.',
                status: "success",
                position: isMobileSize ? "bottom" : "top-left",
                duration: 1000
            });

        } catch (e) {
            errorAddToCart(i, e.response.data.errors[0].message)
        } 
        return
    }


    const addQuantityItemCart = async (quantidade, i, ean, isModal = false) => {
        const data = products.map((item, index) => {
          if (index === i) {
            return {...item, quantidade: quantidade + 1, loading: true};
          }
          return item;
        });
        setProducts(data)
    
        let dataPost = {
          documento: dataUser.documento,
          ean: ean,
          quantidade: isModal ? quantidade : quantidade + 1
        }

        try {
          await api.post('/motorDePontos/carrinho', dataPost);
          sucessoAddToCart(i, quantidade, isModal)
        } catch (e) {
          errorAddToCart(i, e.response.data.errors[0].message)
        } 
    
    }
    
      const sucessoAddToCart = (i, quantidade, isModal = false) => {
        const data = products.map((item, index) => {
          if (index === i) {
            return {...item, loading: false, quantidade: isModal ? quantidade : quantidade + 1, pontosTotal: isModal ? quantidade * item.pontosUnitario : (item.pontosTotal ? item.pontosTotal : item.pontos) + item.pontosUnitario, valorDescontoTotal:  isModal ? quantidade * item.valorDescontoUnitario : item.valorDescontoTotal + item.valorDescontoUnitario};
          }
          return item;
        });
        setProducts(data)
    
        if(isModal){
          let newTotal = 0
          data.map(item => newTotal = newTotal + item.pontosTotal)
          setTotalPontos(newTotal.toString())
        }else{
          let newTotal = Number(totalPontos)
          newTotal = newTotal + data[i].pontosUnitario
          setTotalPontos(newTotal.toString())
        }

        dispatchStore({ 
            type: "UPDATE_QUANT_CARRINHO", 
            quantidade: data.length, 
            products: data
        })

        getToast({
            messageError: 'Carrinho atualizado!',
            status: "success",
            position: isMobileSize ? "bottom" : "top-left",
            duration: 1000
        });
      }
    
      const sucessoDecreaseToCart = (i, quantidade) => {
        const data = products.map((item, index) => {
          if (index === i) {
            return {...item, loading: false, quantidade: quantidade - 1, pontosTotal: (item.pontosTotal ? item.pontosTotal : item.pontos) - item.pontosUnitario, valorDescontoTotal: item.valorDescontoTotal - item.valorDescontoUnitario};
          }
          return item;
        });
    
        let newTotal = Number(totalPontos)
        newTotal = newTotal - data[i].pontosUnitario
        setTotalPontos(newTotal.toString())
    
        setProducts(data)
        dispatchStore({ 
            type: "UPDATE_QUANT_CARRINHO", 
            quantidade: data.length, 
            products: data
        })

        getToast({
            messageError: 'Carrinho atualizado!',
            status: "success",
            position: isMobileSize ? "bottom" : "top-left",
            duration: 1000
        });
    }
    
      const errorAddToCart = (i, messageError = "") => {
        const data = products.map((item, index) => {
          if (index === i) {
            return {...item, loading: false};
          }
          return item;
        });
        setProducts(data)

        getToast({
            messageError: messageError,
            status: "error",
            position: isMobileSize ? "bottom" : "top-left"
        });

      }
    
      const decreaseQuantityItemCart = async (quantidade, ean, i, isModal = false) => {
        
        const data = products.map((item, index) => {
          if (index === i) {
            return {...item, quantidade: isModal ? quantidade : quantidade - 1, loading: true};
          }
          return item;
        });
    
        if(quantidade == 1 || quantidade == 0){
    
          let dataPost = {
            documento: dataUser.documento,
            ean: ean,
            quantidade: 0
          }
    
          try {
            await api.post('/motorDePontos/carrinho', dataPost);
            let data = products.filter(item => (item.ean != ean));
            setProducts(data) 
            dispatchStore({ 
              type: "UPDATE_QUANT_CARRINHO", 
              quantidade: data.length, 
              products: data
            })
    
            let newTotal = 0
            data.map((item) => newTotal = newTotal + (item.pontosUnitario * item.quantidade))
            setTotalPontos(newTotal.toString())
    
            getToast({
                messageError: 'Carrinho atualizado.',
                status: "success",
                position: isMobileSize ? "bottom" : "top-left",
                duration: 1000
            });

          } catch (e) {
            errorAddToCart(i, e.response.data.errors[0].message)
          } 
          return
        }
    
        setProducts(data)
    
        let dataPost = {
          documento: dataUser.documento,
          ean: ean,
          quantidade: quantidade - 1
        }
    
        try {
            await api.post('/motorDePontos/carrinho', dataPost);
            sucessoDecreaseToCart(i, quantidade)
        } catch (e) {
            errorAddToCart(i, e.response.data.errors[0].message)
        } 
    
    }

    const openEditValueInput = (quantidade, ean, i) => {
        setShowModalInput(true)
        setCurrentIndex(i)
        setCurrentEan(ean)
        setNewValueQuantity(quantidade)
    }

    function calcularTempoRestante(dataAlvo) {
        const dataAlvoObj = new Date(dataAlvo);
        const dataAtual = new Date()
        const diferencaEmMilissegundos = dataAlvoObj - dataAtual;
        const horas = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60));
        const minutos = Math.floor((diferencaEmMilissegundos % (1000 * 60 * 60)) / (1000 * 60));
        const segundos = Math.floor((diferencaEmMilissegundos % (1000 * 60)) / 1000);
        return { horas, minutos, segundos };
    }

    const ProductsItem = (item, index) => {
        return (
            <div
                key={index}
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    borderColor: '#e0e0e0',
                    borderWidth: 1,
                    marginTop: 8,
                    paddingRight: 10,
                    paddingBottom: 20,
                    borderBottom: '1px solid #e0e0e0'
                }}
            >
            <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                
                <div style={{ justifyContent: 'center', marginRight: 10,  marginTop: 10}}>
                    <img src={item.imagem } style={{ width: 72, height: 74 }} alt="Product" />
                </div>
                
                <div style={{ flex: 2, display: 'flex', marginTop: 10, flexDirection: 'column', marginTop: 20 }}>
                    <div style={{ display: 'flex', width: 100, alignItems: 'center', justifyContent: 'center', right: 0, top: 0, backgroundColor: '#EC1C24', borderRadius: 999, paddingLeft: 5, paddingRight: 5}}>
                        <span style={{ fontSize: 14, color: '#fff', fontWeight: '600', }}>
                            {item.pontosTotal ? item.pontosTotal : item.pontos} Pontos
                        </span>
                    </div>
                    <span style={{ fontSize: 12, fontWeight: '600', color: '#10125A', marginTop: 10 }}>{item.industria}</span>
                    <span numberOfLines={2} style={{fontWeight: '500', fontSize: 14, color: '#10125A' }}>{item.ean}</span>
                    
                    <p style={{ fontSize: 14, color: '#EC1C24', fontWeight: '600', }}>
                        {item?.valorDescontoTotal ? formattingNumberWithDecimals(item?.valorDescontoTotal).replace('.', ',') :  formattingNumberWithDecimals(item?.valorDesconto * item.quantidade).replace('.', ',')} Desconto total
                    </p>
                    <div style={{ flexDirection: 'row', width: 140, borderRadius: 9999, marginTop: -16, alignItems: 'center', display: 'flex', }}>
                        {item.loading ? 
                            <div style={{ height: 26.5 }}>
                                <Spinner color="#EC1C24" size={'sm'} />
                            </div>
                        :
                        <>
                            <button disabled={item.quantidade == 1} onClick={() => decreaseQuantityItemCart(item.quantidade, item.ean, index)} style={{ opacity: item.quantidade == 1 ? 0.4 : 1, height: 22, width: 22, backgroundColor: '#EC1C24', borderRadius: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={minus} style={{  width: 12, height: 12 }} alt="Minus" />
                            </button>
                            <button style={{ width: 50 }} onClick={() => openEditValueInput(item.quantidade, item.ean, index)}>
                                <span style={{ paddingHorizontal: 10, color: '#10125A', fontSize: 22, fontWeight: 'bold', textAlign: 'center' }}>
                                    {item.quantidade}
                                </span>
                            </button>
                            <button onClick={() => addQuantityItemCart(item.quantidade, index, item.ean, false)} style={{ height: 22, width: 22, backgroundColor: '#EC1C24', borderRadius: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={plus} style={{ tintColor: '#fff', width: 12, height: 12 }} alt="Plus" />
                            </button>
                        </>
                        }
                        {!item.loading &&
                            <button onClick={() => excludeProduct(item.ean, index)} style={{ position: 'absolute', right: 0, height: 22, width: 22, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={delete_icon} style={{ tintColor: '#fff', width: 18, height: 18 }} alt="Plus" />
                            </button>
                        }
                    </div>
                </div>
            </div>
            </div>
        );
    };

    const ProductsItemActivaded = (item, index) => {
        return (
            <div
                key={index}
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    borderColor: '#e0e0e0',
                    borderWidth: 1,
                    marginTop: 8,
                    paddingRight: 10,
                    paddingBottom: 20,
                    borderBottom: '1px solid #e0e0e0'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                    
                    <div style={{ justifyContent: 'center', marginRight: 10,  marginTop: 10}}>
                        <img src={item.imagem } style={{ width: 72, height: 74 }} alt="Product" />
                    </div>
                    
                    <div style={{ flex: 2, display: 'flex', marginTop: 10, flexDirection: 'column', marginTop: 20 }}>
                        <div style={{ display: 'flex', width: 100, alignItems: 'center', justifyContent: 'center', right: 0, top: 0, backgroundColor: '#EC1C24', borderRadius: 999, paddingLeft: 5, paddingRight: 5}}>
                            <span style={{ fontSize: 14, color: '#fff', fontWeight: '600', }}>
                                {item.pontosTotal ? item.pontosTotal : item.pontos} Pontos
                            </span>
                        </div>
                        <span style={{ fontSize: 12, fontWeight: '600', color: '#10125A', marginTop: 10 }}>{item.industria}</span>
                        <span numberOfLines={2} style={{fontWeight: '500', fontSize: 14, color: '#10125A' }}>{item.ean}</span>
                        
                        <span style={{ fontSize: 14, color: '#EC1C24', fontWeight: '600', }}>
                            {item?.valorDescontoTotal ? formattingNumberWithDecimals(item?.valorDescontoTotal).replace('.', ',') :  formattingNumberWithDecimals(item?.valorDesconto * item.quantidade).replace('.', ',')} Desconto total
                        </span>
                        <span style={{ fontSize: 14, fontWeight: '600', color: '#10125A' }}>{item.quantidade} un.</span>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <Context.Provider>
            {children}
            {/**
            <button className='container-cart-button' onClick={() => {setSidebarOpen(true)}}>
                <img src={cart} style={{width: 25, height: 25, tintColor: '#fff'}} />
                <div style={{display: 'flex', alignItems: 'center',  justifyContent: 'center', width: 20, background: quantItemsCart == 0 ? 'transparent' : '#fff', height: 20, borderRadius: 20,  position: 'absolute', top: -2, right: -15}}>
                    <span style={{color: '#fff', fontWeight: 'bold', fontSize: 15, color: '#EC1C24'}}>
                        {quantItemsCart == 0 ? '' : quantItemsCart}
                    </span>
                </div>
            </button>
            */}
            <Drawer placement="right" onClose={() => setSidebarOpen(false)} isOpen={sidebarOpen}>
                <DrawerOverlay />
                <DrawerContent >
                    {loading ?
                        <Flex  width={'100%'} height={500} justifyContent="center" alignItems={"center"} >
                            <Spinner color="#EC1C24"  size={'xl'} /> 
                        </Flex>
                    :
                        <>
                            <DrawerBody width="100%" minWidth="319px" overflowX="hidden">
                                <>
                                    <div className="headerAbaCarrinhoHeader">
                                        <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                                                <strong style={{color: '#10125A'}}>Carrinho</strong>
                                                {products?.length > 0 &&
                                                    <span style={{ fontWeight: 'bold', color: '#10125A'}}>{products?.length} itens</span>
                                                }
                                            </div>
                                            <div style={{  display: 'flex', alignItems: 'flex-start', marginTop: 5}}>
                                                <img src={close} style={{heigth: 20, width: 20, cursor: 'pointer'}} onClick={() => setSidebarOpen(false)} />
                                            </div>
                                        </div>
                                    </div>
                                    {products.length > 0 &&  !sucessActive && (
                                        <span style={{fontSize: 10, fontWeight: '400', color:  '#344054', textAlign: 'center'}}>
                                            Você pode clicar na quantidade para digitar um valor
                                        </span>
                                    )}
                                     { sucessActive &&
                                        <div style={{width: '100%', padding: 10, marginTop: 100, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                                            <span style={{fontSize: 30, color:  '#344054', textAlign: 'center', fontWeight: '500',}}>
                                                Sua ativação foi feita com <span style={{color: '#66bb6a', fontWeight: 'bold'}}>sucesso!</span>
                                            </span>
                                            <img src={happy} style={{height: 140, width: 140, marginTop: 30}} />
                                        
                                            <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',}}>
                                                <span style={{fontSize: 20, color:  '#344054', fontWeight: '500', textAlign: 'center', paddingBottom: 10}}>
                                                    Faça a compra em até
                                                </span>
                                                <TimerCount date={dateExpire} onTimerFinish={{}} />
                                            </div>

                                            <button onClick={() => {
                                                setSidebarOpen(false)
                                                setTimeout(() => {
                                                    window.location.href="/"
                                                }, 500)
                                            }} style={{height: 50, marginTop: 40, paddingLeft: 20, paddingRight: 20, backgroundColor: '#EC1C24', borderRadius: 999, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <span style={{color: '#fff', fontSize: 20, fontWeight: '600'}}>
                                                    Voltar para o Início
                                                </span>
                                            </button>
                                        
                                        </div>
                                    }

                                    {products.length == 0 && !sucessActive && productsActiveded.length == 0 ?
                                        <div style={{width: '100%', padding: 10, marginTop: 100, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <span style={{fontSize: 30, color:  '#344054', textAlign: 'center', fontWeight: '500'}}>
                                                Seu carrinho está vázio
                                            </span>
                                            <img src={empty} style={{height: 100, width: 100, marginTop: 30}} />
                                            <button onClick={() => {
                                                setSidebarOpen(false)
                                                setTimeout(() => {
                                                   navigate('/resgate')
                                                }, 500)
                                            }} style={{height: 50, cursor: 'pointer', marginTop: 40, paddingLeft: 15, paddingRight: 15, backgroundColor: '#EC1C24', borderRadius: 999, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <span style={{color: '#fff', fontSize: 20, fontWeight: '600'}}>
                                                    Adicionar produtos
                                                </span>
                                            </button>
                                        </div>
                                        :
                                            <>
                                                {!sucessActive &&
                                                    <>
                                                        {products &&
                                                            products.map((item, index) => {
                                                                return (
                                                                    ProductsItem(item, index)
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            </>
                                    }

                                    {productsActiveded.length > 0 && (
                                        <>
                                        <br/>
                                        <span style={{fontSize: 10, fontWeight: '400', color:  '#344054'}}>
                                            Nova ativação disponível quando o tempo acabar
                                        </span>
                                        <br/>
                                        <br/>
                                        <span style={{fontSize: 20, fontWeight: '500', color:  '#344054', textAlign: 'center'}}>
                                            Você tem um desconto ativo em andamento
                                        </span>
                                        <br/><br/>
                                        <span style={{fontSize: 20, fontWeight: '500', color:  '#344054', textAlign: 'center'}}>
                                            Faça o pagamento em
                                        </span>
                                        <br/>
                                        <TimerCount date={dateExpire} onTimerFinish={() => {
                                            dispatchCart({ type: "CHANGE_SHOW_CART", isShow: false})
                                            getToast({
                                                messageError: 'Tempo esgotado para fazer a compra',
                                                status: "error",
                                                position: isMobileSize ? "bottom" : "top-left"
                                            });
                                        }} />
                                        </>
                                    )}

                                    {productsActiveded.length > 0 &&
                                        productsActiveded.map((item, index) => {
                                            return (
                                                ProductsItemActivaded(item, index)
                                            )
                                        })
                                    }
                                
                                    <div style={{height: 50}} />
                                </>
                            </DrawerBody>
                            {products.length > 0 && (
                                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 40}}>
                                    <button onClick={() => confirmActiveDiscount()} disabled={loadingActive} style={{height: 55, width: '80%', borderRadius: 9999, backgroundColor: '#EC1C24', display: 'flex', alignItems: 'center', justifyContent: 'center', shadowColor: "#101828", boxShadow: '0px 3px 21px -3px rgba(0,0,0,0.45)'}}>
                                        <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <span style={{fontSize: 20, fontWeight: '500', color: '#fff'}}>
                                                Finalizar carrinho
                                            
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            )}
                        </>
                    }
                </DrawerContent>
            </Drawer>
            <Modal
                isOpen={showModalInput}
                onClose={() => setShowModalInput(false)}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent maxWidth={isMobileSize ? "90%" : '40%'}>
                    <ModalHeader padding={7}>
                        Digite a quantidade desejada
                        <span style={{ fontSize: 12, color: 'rgba(0,0,0,.55)' }}>
                            <br />
                            Colocando 0 excluirá o produto do carrinho
                        </span>
                    </ModalHeader>
                        <ModalCloseButton _focus={{border: 'none'}} />
                    <ModalBody pb={6}>
                        <span style={{ fontSize: 15, marginLeft: 5, fontWeight: 500, color: 'gray' }} >
                        Quantidade de produtos
                        </span>
                        <Flex padding={1} borderRadius={8} width={300} flexDirection="column">
                        <Input
                            type='number'
                            autoFocus
                            style={{
                                width: 250,
                                paddingLeft: 10,
                                padding: 10,
                                border: '1px solid black',
                                borderRadius: 5,
                                fontSize: 16
                            }}
                            placeholder='Digite a quantidade'
                            onChange={(e) => setNewValueQuantity(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    if(!newValueQuantity) return
                                    if(newValueQuantity == '0'){
                                        setShowModalInput(false)
                                        return excludeProduct(currentEan, currentIndex)
                                    }
                                    addQuantityItemCart(Number(newValueQuantity), currentIndex, currentEan, true)
                                    setShowModalInput(false)
                                }
                            }}
                            value={newValueQuantity}
                        />
                        </Flex>
                    </ModalBody>
                    <Flex alignItems={'flex-end'} justifyContent={'flex-end'} padding={5}>
                        <Button
                            colorScheme="red"
                            mr={3}
                            onClick={() => {
                                if(!newValueQuantity) return
                                if(newValueQuantity == '0'){
                                    setShowModalInput(false)
                                    return excludeProduct(currentEan, currentIndex)
                                }
                                addQuantityItemCart(Number(newValueQuantity), currentIndex, currentEan, true)
                                setShowModalInput(false)
                            }}
                            disabled={loading}
                            minWidth={100}
                        >
                        {loading ?
                            <Spinner color="white" />
                            :
                            'Confirmar'
                        }
                        </Button>
                    </Flex>
                </ModalContent>
            </Modal>
        </Context.Provider>
    );
}; 

export const Context = createContext({});
export default CartButton;


