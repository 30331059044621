import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import api from "../services/api";
interface PrivateRouteProps {
  children: any;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const { user } = useAuth();
  let tokenAdmin = localStorage.getItem("@DClube:Admintoken")
  return (user || tokenAdmin) ? children : <Navigate to="/" />;
}

export default PrivateRoute;
