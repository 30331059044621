export const cepCorreiosExists = async (cep: string) => {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    const data = await response.json();
    return data;
  } catch (error: any) {
    const messageError = error?.response?.data?.errors?.[0]?.message;
    console.log(messageError);
    return false;
  }
};
