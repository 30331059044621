import { Button, MenuButton } from "@chakra-ui/react";
import TimerCount from "components/Header/components/timer";

interface MenuButtonHeaderProps {
  name: string;
  onClick: () => void;
  arrowIcon?: any;
  dateExpire?: any;
  onFinish?: () => void;
}

export const MenuButtonHeader = ({
  name,
  onClick,
  arrowIcon,
  dateExpire = '',
  onFinish
}: MenuButtonHeaderProps) => (
  <MenuButton
    style={{
      paddingBottom: dateExpire ? 20: 6
    }}
    width="100%"
    alignItems="center"
    textAlign="left"
    marginTop={ dateExpire ? 3 : -0.5}
    fontSize="18px"
    fontWeight="semibold"
    color="#10125A"
    padding={2}
    backgroundColor="transparent"
    _hover={{ backgroundColor: "transparent" }}
    _active={{ backgroundColor: "transparent" }}
    _focus={{ backgroundColor: "transparent" }}
    borderBottom="2px solid #707070"
    borderBottomWidth="100%"
    borderRadius={0}
    as={Button}
    rightIcon={arrowIcon}
    onClick={onClick}
  >
    {name}
  </MenuButton>
);
