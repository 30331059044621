import axios from "axios";
import {
  sessionTimeoutInterceptor,
  successToasterInterceptor,
  errorInterceptor
} from "./middlewares";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": `${process.env.REACT_APP_BASE_KEY}`,
    Accept: "application/json",
  },
});

api.interceptors.request.use((config: any) => {
  config.headers.Authorization = localStorage.getItem("@DClube:Admintoken") ?? localStorage.getItem("@DClube:token") ?? "";
  return config;
});

// api.interceptors.request.use(authorizationInterceptor);
api.interceptors.response.use(undefined, sessionTimeoutInterceptor); // Desativado só enquanto debugamos o problema em prod
api.interceptors.response.use(successToasterInterceptor);
// api.interceptors.response.use(successToasterInterceptor, errorInterceptor);

export default api;
