import { useNavigate } from "react-router-dom";
import { Menu, MenuList, Box, Button, Text, Flex, MenuItem } from "@chakra-ui/react";

import { ISubCategory } from "interfaces/categories/ICategory";
import { useAuth } from "hooks/useAuth";

import { MenuButtonHeader } from "components/MenuButtonHeader";
import { MenuItemHeader } from "components/MenuItemHeader";
import { useContext, useEffect, useState } from "react";
import api from "services/api";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Context as ContextStore } from '../../../contexts/store';
import { Context as  ContextCart } from '../../../contexts/cart';
import { Context as  ContextActivated } from '../../../contexts/activated';

interface SubCategoryProps extends ISubCategory {
  subCategoryChildrenName?: string;
  subCategoryChildrenId?: number;
  subCategoriaId?: number;
  isChildren?: boolean;
  categoriaSelecionadaId?: number;
  uri?: string;
  uriPai?: string;
  uriFilho?: string;
}

interface SubMenuCategoriesProps extends ISubCategory {
  subCategories: SubCategoryProps[];
  handleShowMenuList: (categoryId: number) => void;
  arrowIcon: any;
  setIsArrowDown: (value: boolean) => void;
  isArrowDown: boolean;
  categoryIdRef: any;
  categoryRefName: any;
  categoriaSelecionada: any;
  handleCloseMenu: () => void;
}

let todos = false

export const MenuCategoriesMobile = ({
  subCategories,
  handleShowMenuList,
  arrowIcon,
  setIsArrowDown,
  isArrowDown,  
  categoriaSelecionada,
  categoryIdRef,
  categoryRefName,
  handleCloseMenu,
}: SubMenuCategoriesProps) => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [saldoPromotora, setSaldoPromotora] = useState<number | string>(0);
  const [isPromotora, setIsPromotora] = useState<boolean>(false);
  const [subCategoriesState, setSubCategoriesState] = useState<any>(
    {
      showDiscounts: false,
      subCategories: subCategories ?? []
    });

  const [stateActivated, dispatchActivated] = useContext<any>(ContextActivated);
  const [quantityActivated, setQuantityActivated] = useState<number>(0);
  const [showPointsMenu, setShowPointsMenu] = useState<boolean>(true);

  useEffect(() => {
    verifyHaveCartActive()
  }, [])

  useEffect(() => {
    setQuantityActivated(stateActivated.quantidade)
  }, [stateActivated])

  useEffect(() => {
    checkPromotora()
  }, [])

  useEffect(() => {
    setSubCategoriesState({
      showDiscounts: subCategoriesState?.showDiscounts && subCategoriesState?.showDiscounts,
      subCategories: subCategories ?? []
    })
  }, [subCategories])

  const verifyHaveCartActive = async () => {
    let { data } = await api.get("/motorDePontos/consultarCarrinhoAtivo");
    if(data.length > 0){
      dispatchActivated({ type: "UPDATE_QUANT_ACTIVATED", quantidade: data.length})
      setQuantityActivated(data.length)
    }else{
      dispatchActivated({ type: "UPDATE_QUANT_ACTIVATED", quantidade: 0})
    }
  }

  function handleSignOut() {
    signOut();
    navigate("/");
  }

  const changeStateCategoriesOpen = () => {

    let response
    let data = {...subCategoriesState}

    if(todos && !subCategoriesState.showDiscounts){
      response = {
        showDiscounts: false,
        subCategories: subCategories ?? []
      }
      setSubCategoriesState(response)
      return
    }

    if(data?.showDiscounts == true){
      response = {
        showDiscounts: !data?.showDiscounts,
        subCategories: subCategories ?? []
      }
      setSubCategoriesState(response)
    }else{
      response = {
        showDiscounts: true,
        subCategories: subCategories ?? []
      }
      setSubCategoriesState(response)
    }

  }

  const checkPromotora = async () => {
    let userStorage:any = localStorage.getItem('@DClube:user'); 
    userStorage = JSON.parse(userStorage)
    if(userStorage?.promotora == true){
      setIsPromotora(true)
      const response = await api.get('/pontuacao/saldo/promotora');
      setSaldoPromotora(response.data.saldo)
    }else{
      setIsPromotora(false)
    }
  }

  function handleCategory({
    uri,
    uriPai,
    uriFilho,
    categoriaSelecionadaId,
    descricao,
    categoriaId,
    categoriaPaiId,
    subCategoryChildrenName,
    subCategoryChildrenId,
    isChildren,
  }: SubCategoryProps) {
    
    var url = `/categoria/${uriPai}`;
    if (uri) { url += `/${uri}` }
    if (uriFilho) { url += `/${uriFilho}` }

    var subCategoriasSelected = subCategories.filter(x => x.uri === uri)
    var subs;

    if (subCategoriasSelected.length > 0){
      subs = subCategoriasSelected[0].subCategoriaFilho
    }
    
    navigate(url, {
    // navigate(`/categoria`, {
      state: {
        uri: uri,
        uriPai: uriPai,
        uriFilho: uriFilho,
        categorias: subs,
        subCategoriesPai: categoriaSelecionada?.subCategoria,
        subCategories: subs,
        categoriaSelecionadaId,
        categoriaSelecionadaNome: descricao,
        categoriaSelecionadaPai: categoriaSelecionada?.descricao
        // categoriaId: subCategoryChildrenId
        //   ? subCategoryChildrenId
        //   : categoriaPaiId,
        // categoriaName: categoryRefName,
        // subCategoryName: descricao,
        // subCategoryId: categoriaId,
        // subCategoryChildrenName,
        // subCategoryChildrenId,
        // cleanSubCategoryId: true,
        // isChildren,
      },
    });
    handleCloseMenu();
  }

  return (
    <Box position="relative" width="100%">
      {isPromotora && 
        <Menu>
          <MenuButtonHeader onClick={() => {navigate("/extrato-pontos"), handleCloseMenu();}}
            name={`Saldo: ${saldoPromotora ?? '0'}`}
          />
        </Menu>
      }
      
      <Menu>
        <MenuButtonHeader
          name="Home"
          onClick={() => {
            navigate("/home");
            handleCloseMenu();
          }}
        />
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="Meus pontos"
          onClick={() => {
            setShowPointsMenu(!showPointsMenu)
          }}
          arrowIcon={!showPointsMenu ? (
            <FiChevronUp fontSize={30} />
          ) : (
            <FiChevronDown fontSize={30} />
          )}
        />

        <MenuList width="100%" height="100%">
          <Flex overflowY="scroll" width="100%" minWidth="319px">
            <MenuItem
              flexWrap="wrap"
              flex="1 1 50px"
              marginLeft="30px"
              padding={0}
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              _focus={{ backgroundColor: "transparent" }}
              lineHeight={1}
            >
              <Flex flexDirection="column">
                <button style={{border: 'none', width: 280}} onClick={() => {
                  navigate('/resgate')
                  handleCloseMenu();
                  setShowPointsMenu(false)
                }}>
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    color="#10125A"
                    textAlign={"left"}
                  >
                    Usar pontos
                  </Text>
                </button>
              </Flex>
            </MenuItem>
          </Flex>
          <Flex overflowY="scroll" width="100%" minWidth="319px">
            <MenuItem
              flexWrap="wrap"
              flex="1 1 50px"
              marginLeft="30px"
              padding={0}
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              _focus={{ backgroundColor: "transparent" }}
              lineHeight={1}
            >
              <Flex flexDirection="column">
                <button style={{border: 'none', width: 280}} onClick={() => {
                  navigate("/ativos")
                  handleCloseMenu();
                  setShowPointsMenu(false)
                }}>
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    color="#10125A"
                    textAlign={"left"}
                  >
                    Produtos ativos
                  </Text>
                </button>
              </Flex>
            </MenuItem>
          </Flex>
          <Flex overflowY="scroll" width="100%" minWidth="319px">
            <MenuItem
              flexWrap="wrap"
              flex="1 1 50px"
              marginLeft="30px"
              padding={0}
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              _focus={{ backgroundColor: "transparent" }}
              lineHeight={1}
            >
              <Flex flexDirection="column">
                <button style={{border: 'none', width: 280}} onClick={() => {
                   navigate("/extrato-de-pontos");
                   handleCloseMenu();
                   setShowPointsMenu(false)
                }}>
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    color="#10125A"
                    textAlign={"left"}
                  >
                    Extrato de pontos
                  </Text>
                </button>
              </Flex>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="Benefícios"
          onClick={() => {
            navigate("/beneficios");
            handleCloseMenu();
          }}
        />
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="Minhas Economias"
          onClick={() => {
            navigate("/transacao");
            handleCloseMenu();
          }}
        />
      </Menu>

      

      {/* <Menu>
        <MenuButtonHeader
          name="Promoções"
          onClick={() => {
            navigate("/promocoes");
            handleCloseMenu();
          }}
        />
      </Menu> */}

      <Menu>
        <MenuButtonHeader
          name="Todos"
          onClick={() => {
            handleShowMenuList(0);
            changeStateCategoriesOpen()
            todos = !todos
          }}
          arrowIcon={subCategoriesState?.showDiscounts && categoryIdRef == 0 ? (
            <FiChevronUp fontSize={30} />
          ) : (
            <FiChevronDown fontSize={30} />
          )}
        />
        <MenuList width="100%" style={{padding: 3}}>
          <MenuItemHeader
            handleCategory={handleCategory}
            subCategories={subCategoriesState.subCategories}
            handleCloseMenu={handleCloseMenu}
          />
        </MenuList>
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="Bebidas"
          onClick={() => {
            handleShowMenuList(29);
            changeStateCategoriesOpen()
            /*
            if (categoryIdRef === 29) {
              setIsArrowDown(!isArrowDown);
            }
            */
          }}
          arrowIcon={subCategoriesState?.showDiscounts && categoryIdRef == 29 ? (
            <FiChevronUp fontSize={30} />
          ) : (
            <FiChevronDown fontSize={30} />
          )}
        />
        <MenuList width="100%" style={{padding: 3}}>
          {subCategoriesState.subCategories?.length > 0 ? 
            <MenuItemHeader
              handleCategory={handleCategory}
              subCategories={subCategoriesState.subCategories}
            />
          :
            <Text className="no-data-menu" style={{fontSize: 14, marginLeft: 3, fontWeight: 'bold', color: '#10125A'}}>
              Em breve mais produtos
            </Text>
          }
        </MenuList>
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="Alimentos"
          onClick={() => {
            handleShowMenuList(1);
            changeStateCategoriesOpen()
            if (categoryIdRef === 1) {
              //setIsArrowDown(!isArrowDown);
            }
          }}
          arrowIcon={subCategoriesState?.showDiscounts && categoryIdRef == 1 ? (
            <FiChevronUp fontSize={30} />
          ) : (
            <FiChevronDown fontSize={30} />
          )}
        />
        <MenuList width="100%" style={{padding: 3}}>
          {subCategoriesState.subCategories.length > 0 ? 
            <MenuItemHeader
              handleCategory={handleCategory}
              subCategories={subCategoriesState.subCategories}
            />
          :
            <Text className="no-data-menu" style={{fontSize: 14, marginLeft: 3, fontWeight: 'bold', color: '#10125A'}}>
              Em breve mais produtos
            </Text>
          }
        </MenuList>
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="Beleza e cuidados"
          onClick={() => {
            handleShowMenuList(41);
            changeStateCategoriesOpen()
          }}
          arrowIcon={subCategoriesState?.showDiscounts && categoryIdRef == 41 ? (
            <FiChevronUp fontSize={30} />
          ) : (
            <FiChevronDown fontSize={30} />
          )}
        />
        <MenuList width="100%" style={{padding: 3}}>
          {subCategoriesState.subCategories.length > 0 ? 
            <MenuItemHeader
              handleCategory={handleCategory}
              subCategories={subCategoriesState.subCategories}
            />
          :
            <Text className="no-data-menu" style={{fontSize: 14, marginLeft: 3, fontWeight: 'bold', color: '#10125A'}}>
              Em breve mais produtos
            </Text>
          }
        </MenuList>
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="Higiene e saúde"
          onClick={() => {
            handleShowMenuList(85);
            changeStateCategoriesOpen()
            //setIsArrowDown(!isArrowDown);
          }}
          arrowIcon={subCategoriesState?.showDiscounts && categoryIdRef == 85 ? (
            <FiChevronUp fontSize={30} />
          ) : (
            <FiChevronDown fontSize={30} />
          )}
        />
        <MenuList width="100%" style={{padding: 3}}>
          {subCategoriesState.subCategories.length > 0 ? 
            <MenuItemHeader
              handleCategory={handleCategory}
              subCategories={subCategoriesState.subCategories}
            />
          :
            <Text className="no-data-menu" style={{fontSize: 14, marginLeft: 3, fontWeight: 'bold', color: '#10125A'}}>
              Em breve mais produtos
            </Text>
          }
        </MenuList>
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="infantil e bebê"
          onClick={() => {
            handleShowMenuList(119);
            changeStateCategoriesOpen()
            //setIsArrowDown(!isArrowDown);
          }}
          arrowIcon={subCategoriesState?.showDiscounts && categoryIdRef == 119 ? (
            <FiChevronUp fontSize={30} />
          ) : (
            <FiChevronDown fontSize={30} />
          )}
        />
        <MenuList width="100%" style={{padding: 3}}>
          {subCategoriesState.subCategories.length > 0 ? 
            <MenuItemHeader
              handleCategory={handleCategory}
              subCategories={subCategoriesState.subCategories}
            />
          :
            <Text className="no-data-menu" style={{fontSize: 14, marginLeft: 3, fontWeight: 'bold', color: '#10125A'}}>
              Em breve mais produtos
            </Text>
          }
        </MenuList>
      </Menu>

      <Menu>
        <MenuButtonHeader
          name="Limpeza"
          onClick={() => {
            handleShowMenuList(137);
            changeStateCategoriesOpen()
            //setIsArrowDown(!isArrowDown);
          }}
          arrowIcon={subCategoriesState?.showDiscounts && categoryIdRef == 137 ? (
            <FiChevronUp fontSize={30} />
          ) : (
            <FiChevronDown fontSize={30} />
          )}
        />
        <MenuList width="100%" style={{padding: 3}}>
          {subCategoriesState.subCategories.length > 0 ? 
            <MenuItemHeader
              handleCategory={handleCategory}
              subCategories={subCategoriesState.subCategories}
            />
          :
            <Text className="no-data-menu" style={{fontSize: 14, marginLeft: 3, fontWeight: 'bold', color: '#10125A'}}>
              Em breve mais produtos
            </Text>
          }
        </MenuList>
      </Menu>
      <Menu>
        <MenuButtonHeader
          name="Histórico de Economia"
          onClick={() => {
            navigate("/transacao");
            handleCloseMenu();
          }}
        />
      </Menu>
      <Menu>
        <MenuButtonHeader
          name="Meus amigos"
          onClick={() => {
            navigate("/meus-amigos");
            handleCloseMenu();
          }}
        />
      </Menu>
      <Menu>
        <MenuButtonHeader
          name="Alterar senha"
          onClick={() => {
            navigate("/alterar-senha");
            handleCloseMenu();
          }}
        />
      </Menu>
      {isPromotora && 
        <>
          <Menu>
            <MenuButtonHeader
              name="Extrato de pontos"
              onClick={() => {
                navigate("/extrato-pontos");
                handleCloseMenu();
              }}
            />
          </Menu>
          <Menu>
            <MenuButtonHeader
              name="Ranking"
              onClick={() => {
                navigate("/ranking");
                handleCloseMenu();
              }}
            />
          </Menu> 
        </>
      }
      <Flex marginTop={10} alignItems="center" justifyContent="center">
        <Button
          backgroundColor="#EC1C24"
          textAlign="center"
          
          fontSize="18px"
          fontWeight="semibold"
          color="#FFFF"
          _hover={{ backgroundColor: "transparent" }}
          _active={{ backgroundColor: "transparent" }}
          _focus={{ backgroundColor: "transparent" }}
          onClick={() => handleSignOut()}
        >
          Sair
        </Button>
      </Flex>
    </Box>
  );
};
