import React, { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery, Spinner, Flex, Button } from "@chakra-ui/react";
import { Column } from "react-table";

import api from "services/api";
import { useAuth } from "hooks/useAuth";
import { formattingNumberWithDecimals, formattingNumberWithoutDecimals } from "utils";
import { formatDate } from "utils/formattedDate";

import { Table } from "./Table";
import { RenderRowProducts } from "./RenderRowProducts";
import { characterDelimiter } from "utils/characterDelimiter";
import { InputReactMask } from "components/InputReactMask";
import { useCustomToast } from "hooks/useToast";

type LojaProps = {
  estabelecimento: string;
};

type TransacaoItemProps = {
  nomeProduto: string;
  precoUnitario?: number;
  precoTotal: number;
  descontoUnitario?: number;
  descontoTotal: number;
  quantidadeVendida?: number;
};

type TransacoesProps = {
  cupom: string;
  loja: LojaProps;
  data: Date;
  descontoTotal: number;
  transacaoItem?: TransacaoItemProps[];
};

interface TableTransactionDataProps {
  descontoGeral: number;
  transacoes: TransacoesProps[];
}

interface TableTransactionColumnProps {
  cupom: string;
  data: Date;
  descontoTotal: number;
  loja: string;
}

export const TableTransaction = () => {
  const { state } = useLocation();
  const { dataUser } = useAuth();
  const [transactions, setTransactions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState<any>();
  const [dataFim, setDataFim] = useState<any>();
  let transactionItem = [] as any;
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const { getToast } = useCustomToast();

  useEffect(() => {
    getTransaction(true);
  }, []);

  async function getTransaction(first = false) {
    let dataPost
    if(first) {
      const date = new Date();
      date.setDate(date.getDate() - 90);
      
      //setDataInicio(formatDate(date))
      //setDataFim(formatDate(new Date()))

      dataPost = {
        documento: dataUser.documento,
        dataDe: first ? formatDate(date) : dataInicio,
        dataAte: first ? formatDate(new Date()) : dataFim,
      }
    }else{

      const partesDataInicio = dataInicio.split('/');
      const dateInicial = new Date(partesDataInicio[2], partesDataInicio[1] - 1, partesDataInicio[0]);
      const partesDataFinal = dataFim.split('/');
      const dateFinal = new Date(partesDataFinal[2], partesDataFinal[1] - 1, partesDataFinal[0]);
      const dataAtual = new Date();

      dataAtual.setHours(0);
      dataAtual.setMinutes(0);
      dataAtual.setSeconds(0);
      dataAtual.setMilliseconds(0);

      if (dateInicial > dateFinal) {
        getToast({
          messageError: "A data inicial não pode ser maior que a data final.",
          status: "error",
        });
        return ;
      }

      if (dateFinal < dateInicial) {
        getToast({
          messageError: "A data final não pode ser menor que a data inicial.",
          status: "error",
        });
        return ;
      }

      if (dateFinal > dataAtual) {
          getToast({
            messageError: "A data final não pode ser maior que a data atual.",
            status: "error",
          });
          return ;
      }

      dataPost = {
        documento: dataUser.documento,
        dataDe: dataInicio,
        dataAte: dataFim,
      }
    }


    let formattData = dataPost

    const dataAte = formattData.dataAte.split('/').reverse().join('-');
    const dataDe = formattData.dataDe.split('/').reverse().join('-');

    const dataAteObj = new Date(dataAte);
    const dataDeObj = new Date(dataDe);

    dataPost.dataAte = dataAteObj
    dataPost.dataDe = dataDeObj
    
    try {
      setLoading(true);
      const {data} = await api.post('/motorDePontos/extrato', dataPost);

      data.sort((a: any, b: any) => {
        return new Date(b.dataAtivacao).getTime() - new Date(a.dataAtivacao).getTime();
      });
    
      setLoading(false);
      setTransactions(data);
    } catch (error) {
      getToast({
        messageError: "Formatação da data deve ser: DD/MM/AAAA",
        status: "error",
      });
      setLoading(false)
    }
  }

  const parsedTransaction = transactions?.map((transaction: any) => {
    const transactionList = {
      dataAtivacao: formatDate(transaction.dataPonto),
      nome: characterDelimiter(transaction.nome, 45),
      pontosTotal: transaction.pontos,
    };

    return transactionList;
  });

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "dataAtivacao",
      },
      {
        Header: "Titulo",
        accessor: "nome",
      },
      {
        Header: "Pontos Total",
        accessor: "pontosTotal",
        isNumeric: true,
        id: "ponto"
      },
    ],
    []
  );
  
  return (
    <>
      {loading ? (
        <Flex paddingBottom={20}>
          <Spinner color="#EC1C24" />
        </Flex>
      ) : (
        <>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{width: isMobileSize ? 110 : 200}}>
              <InputReactMask
                mask="99/99/9999"
                nameInput="nascimento"
                label="Data inicial:"
                placeholder="00/00/0000"
                width={200}
                isDate
                onChange={(e) => setDataInicio(e.target.value)}
                value={dataInicio}
              />
             </div>
            <div style={{width: isMobileSize ? 10 : 30}} />
            <div style={{width: isMobileSize ? 110 : 200}}>
              <InputReactMask
                mask="99/99/9999"
                nameInput="nascimento"
                label="Data final:"
                placeholder="00/00/0000"
                width={200}
                isDate
                onChange={(e) => setDataFim(e.target.value)}
                value={dataFim}
              />
            </div>
            <div style={{width: isMobileSize ? 10 : 30}} />

            <Button
              onClick={() => {
                if(!dataInicio && !dataFim){
                  return getTransaction(true)
                }
                if(!dataInicio || !dataFim){
                  return  getToast({
                    messageError: "Preencha os dois campos antes de pesquisar!",
                    status: "error",
                  });
                }
                getTransaction()
              }}
              height="40px"
              backgroundColor="#EC1C24"
              fontSize={isMobileSize ? "14px" : "18px"}
              fontWeight="bold"
              color="#FFFF"
              marginTop={ isMobileSize ? 2 : 6}
            >
              Pesquisar
            </Button>
            
          </div>
          <br/>
          <Table
            transacaoItem={transactionItem}
            columns={columns}
            data={parsedTransaction ?? []}
            isExpanded={false}
            renderRowSubComponent={RenderRowProducts}
          />

          {parsedTransaction.length == 0 &&
            <span style={{fontSize: 20, color: "#10125A", fontWeight: 500, marginTop: -40}}>
              Não foram encontrados registros
            </span>
          }
        </>
      )}
    </>
  );
};
