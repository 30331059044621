import {
  useState,
  useEffect,
  cloneElement,
  ReactElement,
  JSXElementConstructor,
} from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useAuth } from "hooks/useAuth";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import './styles.scss'

interface LayoutProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

const loggedOutoutes = ["/"];

export const Layout = ({ children }: LayoutProps) => {
  const { user, signOut, useDisclosure } = useAuth();
  const { isOpen } = useDisclosure();
  const [isBlueBackGround, setIsBlueBackGround] = useState(false);
  const location = useLocation();
  const toCloseHeader = location?.pathname.includes("/categoria");

  useEffect(() => {
    if (isOpen && user) {
      setIsBlueBackGround(true);
    } else {
      setIsBlueBackGround(false);
    }
  }, [isOpen, isBlueBackGround, user]);

  return (
    <>
      <Header 
        showLogin={location.pathname == '/'}
        setIsBlueBackGround={setIsBlueBackGround}
        toCloseHeader={toCloseHeader}
        isLogged={!loggedOutoutes.includes(location.pathname)}
      />
      <Box
        id="box-interface"
        //backgroundColor={isBlueBackGround ? "RGBA(22,32,86, 0.7)" : "none"}
        zIndex={1}
      >
        {cloneElement(children, {
          isOpen,
        })}
      </Box>

      <Footer />
    </>
  );
};
