import React, {
  ReactNode,
  Fragment,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from "react";
import { NavLink } from "react-router-dom";

import {
  ICategory,
  IChildrenSubCategory,
} from "interfaces/categories/ICategory";
import { IBrand } from "interfaces/brand/IBrand";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  useMediaQuery,
  Heading,
  Divider,
  RadioGroup,
  Radio,
  Box,
} from "@chakra-ui/react";
import MENU_TRANSACOES_MOCK from "mocks/menuTransacoes";

interface NavMenuProps {
  isShow?: boolean;
  isTransaction?: boolean;
  menuList: ICategory[];
  textDecoration?: string;
  textDecorationColor?: string;
  fontColorActive?: string;
  children?: ReactNode;
  handleToggleShowCategories?: () => void;
  categoryName?: string;
  categoryId?: number;
  subCategoryId?: number;
  subCategoryNameRef?: any;
  subCategoryChildrenName?: string;
  subCategoryChildrenIdRef?: number;
  routeName?: string;
  brands?: IBrand[];
  brand?: IBrand;
  setBrand?: Dispatch<SetStateAction<IBrand>> | any;
  setCategoryId?: any;
  categoryStateId?: number;
}

export const NavMenu = ({
  isShow,
  isTransaction,
  menuList,
  fontColorActive = "#10125A",
  textDecoration = "underline",
  textDecorationColor = "#EC1C24",
  children,
  handleToggleShowCategories,
  categoryName,
  subCategoryNameRef,
  subCategoryChildrenName,
  subCategoryChildrenIdRef,
  categoryId,
  subCategoryId,
  routeName,
  brands,
  brand,
  setBrand,
  setCategoryId,
  categoryStateId,
}: NavMenuProps) => {
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const [isShowMenu, setIsShowMenu] = useState(true);
  const [isShowSubCategories, setIsShowSubCategories] = useState(true  );
  const [menuListState, setMenuListState] = useState(MENU_TRANSACOES_MOCK);
  const buttonRef: any = useRef(null);

  useEffect(() => {
    if (isShowMenu && isMobileSize) {
      setIsShowMenu(true);
    }
    if(isTransaction){
      checkPromotora()
    }
  }, [menuList]);

  const checkPromotora = async () => {
    let userStorage:any = await localStorage.getItem('@DClube:user'); 
    userStorage = JSON.parse(userStorage)
    if(userStorage?.promotora == true){
      if (!menuListState.find((e: any) => e.id === 7 || e.id === 8)) {
        setMenuListState(() => [...menuListState, {id: 7,  descricao: "Extrato de pontos promotor(a)", path: "/extrato-pontos"}])
        setMenuListState((prevState) => [...prevState, {id: 8,  descricao: "Ranking promotor(a)", path: "/ranking"}])
      }
    }
  }

  function handleList(
    subCategoriaFilho: IChildrenSubCategory[]
  ): IChildrenSubCategory[] {
    if (!subCategoryChildrenName) {
      return subCategoriaFilho;
    }

    const filterList = subCategoriaFilho.filter(
      (item: any) => item.descricao === subCategoryChildrenName
    );

    return filterList;
  }

  return (
    <Flex flexDirection="column" justifyContent="flex-start" marginRight={10}>
      {isTransaction ? (
        <Flex flexDirection="column" paddingLeft={5} marginTop="55px">
          {menuListState.map(({ descricao, path, id }) => (
            <NavLink to={String(path)} key={id}>
              <Text
                fontSize={isMobileSize ? "15px" : "18px"}
                fontWeight={
                  fontColorActive && descricao === routeName
                    ? "bold"
                    : "normal"
                }
                color={
                  fontColorActive && descricao === routeName
                    ? fontColorActive
                    : "#BABABA"
                }
                textDecorationColor={
                  textDecorationColor ? textDecorationColor : "none"
                }
                _hover={{
                  color: fontColorActive,
                  textDecoration: textDecoration,
                  textDecorationColor: textDecorationColor,
                  fontWeight: "bold",
                }}
                _active={{ background: "transparent" }}
                _focus={{ background: "transparent" }}
              >
                {descricao}
              </Text>
            </NavLink>
          ))}
        </Flex>
      ) : (
        <>
          <Menu>
            <MenuButton
              ref={buttonRef}
              onClick={() => setIsShowMenu(!isShowMenu)}>
              <Text
                textAlign="left"
                fontSize="15px"
                fontWeight="bold"
                color="#10125A">
                Filtros
              </Text>
            <Divider color="#C1C9DC" marginTop="14px" />
            </MenuButton>
            <MenuList
              zIndex={2}
              borderColor="transparent"
              width="280px"
              border="solid 1px transparent">
              <>

                <Heading
                  fontSize="15px"
                  fontWeight="bold"
                  color="#10125A"
                  marginBottom="18.6px">
                  Categoria
                </Heading>

                {!!menuList?.length &&
                  menuList
                    .filter((category) => category.id === categoryId)
                    .map(({ subCategoria }) => {
                      return (
                        !!subCategoria?.length &&
                        subCategoria
                          .filter((item) => item.categoriaId === subCategoryId)
                          .map(
                            ({
                              descricao,
                              categoriaId,
                              categoriaPaiId,
                              subCategoriaFilho,
                            }) => (
                              <Fragment key={categoriaId}>
                                <MenuItem
                                  _hover={{ background: "transparent" }}
                                  onClick={() => { alert('implementar') }}>
                                  <Flex flexDirection="column">
                                    {!isShowSubCategories &&
                                      subCategoryChildrenName ? (
                                      <Text
                                        fontSize="15px"
                                        fontWeight="normal"
                                        color="#10125A"
                                        _hover={{
                                          color: "#EC1C24",
                                        }}
                                        _active={{ color: textDecorationColor }}
                                      >
                                        {descricao} - 1
                                      </Text>
                                    ) : (
                                      <>
                                        {!!subCategoriaFilho?.length &&
                                          handleList(subCategoriaFilho).map(
                                            (item, index) => (
                                              <Box key={index}>
                                                <Box
                                                  onClick={() => {
                                                    setIsShowSubCategories(true);
                                                    setCategoryId(() => item.categoriaId);
                                                    buttonRef?.current && buttonRef.current.onClick();
                                                  }}>
                                                  <Text
                                                    fontSize="15px"
                                                    fontWeight={
                                                      categoryStateId ===
                                                        item.categoriaId
                                                        ? "bold"
                                                        : "normal"
                                                    }
                                                    color={
                                                      categoryStateId ===
                                                        item.categoriaId
                                                        ? "#EC1C24"
                                                        : "#10125A"
                                                    }
                                                    _hover={{
                                                      color: "#EC1C24",
                                                    }}
                                                    _active={{
                                                      color:
                                                        textDecorationColor,
                                                    }}
                                                  >
                                                    {item.descricao} 
                                                  </Text>
                                                </Box>
                                              </Box>
                                            )
                                          )}
                                      </>
                                    )}
                                  </Flex>
                                </MenuItem>
                              </Fragment>
                            )
                          )
                      );
                    })}



                  {/* MARCAS  */}
                {!!brands?.length &&
                  <>
                    {/* <MenuItem>
                      <Divider
                        color="#C1C9DC"
                        marginTop="14px"
                        width="100%"
                        padding={0}
                      />
                    </MenuItem> */}
                    <Heading
                      fontSize="15px"
                      fontWeight="bold"
                      color="#10125A"
                      marginBottom="18.6px">
                      Marca
                    </Heading></>
                }
              </>

              {!!brands?.length &&
                brands.map(({ marcaId, nome, qtdProduto }) => (
                  <MenuItem
                    key={marcaId}
                    fontSize="15px"
                    fontWeight="normal"
                    color="#10125A"
                    _hover={{
                      color: "#EC1C24",
                      background: "transparent",
                    }}
                    _active={{ color: textDecorationColor }}>
                    <RadioGroup
                      onChangeCapture={(e: any) =>
                        setBrand({ nome: e.target.value })
                      }
                      value={brand?.nome}>
                      <Radio
                        value={nome}
                        colorScheme="red"
                        borderColor="#707070"
                      >{`${nome} (${qtdProduto})`}</Radio>
                    </RadioGroup>
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </>
      )}
    </Flex>
  );
};
