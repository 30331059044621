import {
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";

import bannerSeguros from "assets/banners/banner-home-mescla-seguros-logado.png";
import bannerSegurosMobile from "assets/banners/Banner-home-mescla-seguros-mobile-logado.png";
import life from 'assets/icons/life.png';
import odonto from 'assets/icons/odonto.png';
import pet from 'assets/icons/pet.png';
import "./style.scss";
import TimerCount from "components/Header/components/timer";
import { Breadcrumb_v2 } from "components/Breadcrumb/index_v2";
import { useEffect, useRef } from "react";

const SegurosPage = () => {

  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const priceRef = useRef(null);

  const scrollToPrices = () => {
    const yOffset = -100;
    const element = priceRef.current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  return (
    <div className="container-seguros">
      <div className="line-red" />

      <div className="header-title">
        <span className="title">
          DClube Seguro & Múltiplas Assistências
        </span>
        <span className="sub-title">
          Todos os dias com você!
        </span>
      </div>

      <div className="banner">
        {!isMobileSize ? 
          <img src={bannerSeguros} alt="banner" onClick={() => scrollToPrices()} />
        :
          <img src={bannerSegurosMobile} alt="banner" onClick={() => scrollToPrices()} />
        }
      </div>

      <div className="center-text">
        <span className="title">
          Conheça nossos planos de seguros e assistências
        </span>
        <span className="sub-title">
          para garantir tranquilidade e segurança.
        </span>
      </div>

      <Flex className="products-pontos" width="100%" maxWidth="1420" margin="0px auto" justifyContent={"center"} alignItems="center" ref={priceRef}>
          <Flex
            wrap="wrap"
            gap="40px"
            width="100%"
            margin="0px auto"
            justifyContent={'center'}
            
          >
            <div className="container-itens-seguros">
              <div className="item">
                <div className="icon-header">
                  <img src={pet} />
                </div>
                <div className="content">
                  <div className="title">
                    DClube<br/>
                    Seguro Pet
                  </div>
                  <div className="line">
                    <div className="line-item" />
                  </div>

                  <div className="subtitle">
                    Proteja seu amigo<br/>
                    de quatro patas!
                  </div>

                  <div className="price">
                    <div className="price-1">
                      <span className="price-1-title">
                          A partir de
                      </span>
                      <span className="price-1-subtitle">
                        R$
                      </span>
                    </div>
                    <div className="price-2">
                      <span className="price-2-title">
                         37<span style={{fontSize: 60, letterSpacing: -1}}>,</span>
                      </span>
                    </div>

                    <div className="price-3">
                      <span className="price-3-title">
                        60
                      </span>
                      <span className="price-3-subtitle">
                        por mês
                      </span>
                    </div>
                    
                  </div>
                </div>
                <div className="container-butotn-buy">
                  <button className="button-buy" onClick={() =>  window.open('https://app.insur.space/zicard-trade-marketing-digital-ltda/bbcfd775-a993-4b46-9d79-4a6943912c14/seguro-pet/dados-risco', '_blank')}>
                    CONTRATE<br/>
                    AQUI
                  </button>
                </div>
              </div>
            </div>

            <div className="container-itens-seguros">
              <div className="item">
                <div className="icon-header">
                  <img src={life} />
                </div>
                <div className="content">
                  <div className="title">
                    DClube<br/>
                    Seguro Vida
                  </div>
                  <div className="line">
                    <div className="line-item" />
                  </div>

                  <div className="subtitle">
                    Invista na segurança<br/>
                    daqueles que você ama!
                  </div>

                  <div className="price">
                    <div className="price-1">
                      <span className="price-1-title">
                          A partir de
                      </span>
                      <span className="price-1-subtitle">
                        R$
                      </span>
                    </div>
                    <div className="price-2">
                      <span className="price-2-title">
                         10<span style={{fontSize: 60, letterSpacing: -1}}>,</span>
                      </span>
                    </div>

                    <div className="price-3">
                      <span className="price-3-title">
                        70
                      </span>
                      <span className="price-3-subtitle">
                        por mês
                      </span>
                    </div>
                    
                  </div>
                </div>
                <div className="container-butotn-buy">
                  <button className="button-buy" onClick={() =>  window.open('https://app.insur.space/zicard-trade-marketing-digital-ltda/bbcfd775-a993-4b46-9d79-4a6943912c14/seguro-acidentes-pessoais/dados-risco', '_blank')}>
                    CONTRATE<br/>
                    AQUI
                  </button>
                </div>
              </div>
            </div>

            <div className="container-itens-seguros">
              <div className="item">
                <div className="icon-header">
                  <img src={odonto} />
                </div>
                <div className="content">
                  <div className="title">
                    DClube<br/>
                    Seguro Odonto
                  </div>
                  <div className="line">
                    <div className="line-item" />
                  </div>

                  <div className="subtitle">
                    Não deixe a sua saúde<br/>
                    bucal em segundo plano!
                  </div>

                  <div className="price">
                    <div className="price-1">
                      <span className="price-1-title">
                          A partir de
                      </span>
                      <span className="price-1-subtitle">
                        R$
                      </span>
                    </div>
                    <div className="price-2">
                      <span className="price-2-title">
                         39<span style={{fontSize: 60, letterSpacing: -1}}>,</span>
                      </span>
                    </div>

                    <div className="price-3">
                      <span className="price-3-title">
                        90
                      </span>
                      <span className="price-3-subtitle">
                        por mês
                      </span>
                    </div>
                    
                  </div>
                </div>
                <div className="container-butotn-buy">
                  <button className="button-buy" onClick={() =>  window.open('https://app.insur.space/zicard-trade-marketing-digital-ltda/bbcfd775-a993-4b46-9d79-4a6943912c14/seguro-odonto/dados-risco', '_blank')}>
                    CONTRATE<br/>
                    AQUI
                  </button>
                </div>
              </div>
            </div>
          </Flex>
        </Flex>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
};

export default SegurosPage;
