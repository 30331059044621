import { useState, useEffect } from "react";
import { Image, Link, useMediaQuery } from "@chakra-ui/react";

import { MobileFooterVersion } from "./components/MobileFooterVersion";
import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";

import logoDClube from "assets/logo/dclube-logo-footer.png";
import logoZicard from "assets/logo/logo-zicard.svg";
import logoZicardNovo from "assets/logo/logo_atmosphera.png";
import iconFB from "assets/social/simple-facebook.png";
import iconIns from "assets/social/simple-instagram.png";
import iconYT from "assets/social/simple-youtube.png";
import { useAuth } from "hooks/useAuth";

import "./style.scss";

interface FooterProps {
  styles?: React.CSSProperties;
}

export const Footer = ({ styles }: FooterProps) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [colorState, setColorState] = useState("#FFFF");
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const { user } = useAuth();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <footer style={styles} className="footer-container bg-blue">
      {isMobileSize ? (
        <MobileFooterVersion />
      ) : (
        <div className="content">
          <div className="logo">
            <Link href="/home">
              <Image src={logoDClube} alt="Logo do DeClube" />
            </Link>
          </div>
          <div className="informations">
            <div>
              <div className="content1 big-links">
                {!user &&
                  <div className="child1">
                    <a href="/" className="text-red">
                      <strong>Fazer Login</strong>
                    </a>
                  </div>
                }
                <div className="child2">
                  <a href="/faq" className="text-red">
                    <strong>Perguntas Frequentes</strong>
                  </a>
                </div>
                <div className="child3">
                  <a href="/contato" className="text-red">
                    <strong>Contato</strong>
                  </a>
                </div>
              </div>
              <ScrollToTop color={colorState} showTopBtn={showTopBtn} />
            </div>
            <hr />
            <div>
              <div className="content1">
                <div className="child1">
                  <a href="/nossa-historia">Nossa história</a>
                </div>
                <div className="child2">
                  <a href="/no-que-acreditamos">No que acreditamos</a>
                </div>
                <div className="child3">
                  <a href="/trabalhe-conosco">Trabalhe conosco</a>
                </div>
              </div>
              <div className="content2">
                <Image src={iconFB} alt="" style={{cursor: 'pointer'}} onClick={() => window.open('https://www.facebook.com/dclubeoficial', '_blank')}/>
                <Image src={iconIns} alt="" style={{cursor: 'pointer'}} onClick={() => window.open('https://www.instagram.com/dclubeoficial/', '_blank')}/>
                <Image src={iconYT} alt="" style={{cursor: 'pointer'}} onClick={() => window.open('https://www.youtube.com/dclubeoficial', '_blank')}/>
              </div>
            </div>
            <div>
              <div>
                <small>
                  Utilizamos cookies para melhorar a experiência de navegação.{" "}
                  <Link href="/politica-de-privacidade">
                    <small style={{ textDecoration: "underline", fontWeight: "bold" }}>
                      Politicas de Privacidade.{"  "}
                    </small>
                  </Link>
                  <Link href="/termos-e-condicoes">
                    <small
                      style={{
                        textDecoration: "underline",
                        marginLeft: 5,
                        fontWeight: "bold"
                      }}
                    >
                      Termos de Uso.
                    </small>
                  </Link>
                  <Link href="/regulamento">
                    <small
                      style={{
                        textDecoration: "underline",
                        marginLeft: 5,
                        fontWeight: "bold"
                      }}
                    >
                      Regulamento.
                    </small>
                  </Link>
                </small>
              </div>
              <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                <Image src={logoZicardNovo} alt="logo-zicard"  style={{width: '40%'}}/>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};
