import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
  useMediaQuery,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import InputMask from "react-input-mask";
import iconLocation from "assets/icons/pin-location.png";
import cart from "assets/icons/cart.png";
import { useAuth } from "hooks/useAuth";
import api from "services/api";

import { SubHeader } from "./components/SubHeader";
import { CategoriesList } from "./components/CategoriesList";
import { MenuHeaderMobile } from "./components/MenuHeaderMobile";

import "./style.scss";
import { useCustomToast } from "hooks/useToast";
import { cepCorreiosExists } from "utils/cepCorreiosExists";
import { LoginInput } from "pages/login/components/LoginInput";
import { TesteFlag } from "./teste";

import { Context as ContextStore } from '../../contexts/store';
import { Context as  ContextCart } from '../../contexts/cart';
import { formattingNumberWithDecimals, formattingNumberWithDecimalsAndWithoutCoin, formattingNumberWithoutDecimals } from "utils";

interface HeaderProps {
  isLogged: boolean;
  toCloseHeader?: boolean;
  setIsBlueBackGround: any;
  showLogin?: boolean;
}

interface SubCategoryProps {
  categoriaId?: number;
  id?: number;
  descricao: string;
  uri: string;
}

interface CategoryProps {
  id: number;
  descricao: string;
  uri: string;
  subCategoria: SubCategoryProps[];
}

export const Header = ({
  isLogged = false,
  toCloseHeader,
  showLogin = false,
  setIsBlueBackGround,
}: HeaderProps) => {
  const [searchProduct, setSearchProduct] = useState("");
  const { user, signOut, dataUser } = useAuth();
  const [isShowMenuList, setIsShowMenuList] = useState(false);
  const [cep, setCep] = useState('');
  const [cepValue, setCepValue] = useState('');
  const [openCep, setOpenCep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(true);
  const [state, setState] = useState('');

  const [categories, setCategories] = useState<CategoryProps[]>([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState<CategoryProps>();
  const [isCategoryOff, setIsCategoryOff] = useState<boolean>(false);
  const [subCategories, setSubCategories] = useState<SubCategoryProps[]>([]);
  const [isMobileSize] = useMediaQuery("(max-width: 1103px)");
  const [isMobileSizeMini] = useMediaQuery("(max-width: 400px)");
  const [isTabletSize] = useMediaQuery("(max-width: 1240px)");
  const navigate = useNavigate();
  const bodySelected = document.querySelector("body");
  const categoryIdRef = useRef(0);
  const categoryNameRef = useRef("");
  const [saldoPromotora, setSaldoPromotora] = useState<number | string>(0);
  const [isPromotora, setIsPromotora] = useState<boolean>(false);
  const [quantityCart, setQuantityCart] = useState<number>(0);
  const [stateStore, dispatchStore] = useContext<any>(ContextStore);
  const [stateCart, dispatchCart] = useContext<any>(ContextCart);
  const { getToast } = useCustomToast();
  const [showSaldo, setShowSaldo] = useState<boolean>(false);
  const [listPoints, setListPoints] = useState([]);
  

  useEffect(() => {
    setQuantityCart(stateStore.quantidade)
    if(stateStore?.quantidade == 0 && user){
      //saldoPontos()
    }
  }, [stateStore])

  useEffect(() => {
    setListPoints([])
    setShowSaldo(false)
    if (user) {
      saldoPontos();
    }
  }, [user, dataUser]);

  useEffect(() => {
    async function getCategories() {
      try {
        const { data } = await api.get("categoria/listarComSubCategoria");
        setCategories(data);
      } catch (error) {
        console.log(error);
      }
    }
    if (user) {
      getCategories();
    }
    // }, [subCategories, isShowMenuList]);
  }, [user]);

  useEffect(() => {
    if (user) {
      setLoadingCep(true)
      getCep()
      checkPromotora()
    }
  }, [user])

  async function saldoPontos() {
    try {
      const { data } = await api.get("/motorDePontos/saldoPorIndustria");
      if(data){
        setListPoints(data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const gotoLogin = () => {
    var scrollDiv = document.getElementById("login");
    var top = scrollDiv?.offsetTop ?? 0;
    window.scrollTo({ top: top - 40, behavior: 'smooth' });
  }

  function handleLogout() {
    signOut();
    setIsBlueBackGround(false);
    if (bodySelected) {
      bodySelected.style.background = "#FFFF";
    }

    navigate("/");
  }

  function handleSearchProduct(event: string) {
    setSearchProduct(event);
  }

  function handleSendSearchProduct() {
    if (!searchProduct) return;
    navigate(`/busca-geral/${searchProduct}`, {
      state: { nome: searchProduct },
    });
    setTimeout(() => {
      setSearchProduct("");
    }, 2);
  }

  const handleShowMenuList = (categoryId: number, descricao?: string) => {
    categoryIdRef.current = categoryId;
    categoryNameRef.current = descricao || "";
    setSubCategories([]);
    setIsShowMenuList(true);
    if (categoryId === 0) {
      const allSubCategories = categories.map(
        ({ subCategoria }) => subCategoria
      );
      if (allSubCategories.length) {
        setSubCategories(allSubCategories.flat());
        setCategoriaSelecionada(undefined)
      }
      setIsCategoryOff(false)
    } else if (categoryId == 9999){
        setIsCategoryOff(true)
      } else {
        const categorySelected = categories.find(({ id }) => id === categoryId);
        if (categorySelected?.subCategoria?.length) {
          setSubCategories(() => categorySelected?.subCategoria);
          setCategoriaSelecionada(() => categorySelected);
        }
        setIsCategoryOff(false)
      }
  };

  const handleShowMenuListMobile = (categoryId: number) => {
    categoryIdRef.current = categoryId;
    if (categoryId === 0) {
      const allSubCategories = categories.map(
        ({ subCategoria }) => subCategoria
      );
      if (allSubCategories.length) {
        setSubCategories(allSubCategories.flat());
        setCategoriaSelecionada(undefined)
      }
    } else {
      const categorySelected = categories.find(({ id }) => id === categoryId);
      if (categorySelected?.subCategoria?.length) {
        setSubCategories(() => categorySelected?.subCategoria);
        setCategoriaSelecionada(() => categorySelected);
      }else{
        setSubCategories([])
      }
    }
  };

  const getCep = async () => {
    const { data } = await api.get(`cadastro/cep`);


    if (data?.cep) {
      const first = data.cep.substring(0, 5);
      const last = data.cep.substring(8, 5);
      setCep(first + '-' + last)
      setCepValue(data.cep)
      const correiosCepValid = await cepCorreiosExists(data?.cep);
      if (correiosCepValid?.localidade) {
        setState(correiosCepValid?.localidade)
      }
    } else {
      setCepValue('')
      setCep('')
      setState('')
    }
    setLoadingCep(false)
  }

  const saveCep = async () => {
    const value = cepValue.replace(/\D/g, '');
    if (value.length < 8) {
      getToast({ messageError: 'Preencha um cep válido!', status: "error" });
      return
    }
    setLoading(true)

    const params = {
      cep: value
    };

    try {
      await api.put(`/cadastro/cep`, params)
      getToast({
        messageError: "Cep atualizado com sucesso!",
        status: "success",
      });
      const first = value.substring(0, 5);
      const last = value.substring(8, 5);
      setCep(first + '-' + last)
      setOpenCep(false)
      const correiosCepValid = await cepCorreiosExists(first + last);
      if (correiosCepValid?.localidade) {
        setState(correiosCepValid?.localidade)
      }
    } catch (error) {
      getToast({ messageError: 'Houve um erro ao atualizar seu cep', status: "error" });
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  const checkPromotora = async () => {
    let userStorage:any = localStorage.getItem('@DClube:user'); 
    userStorage = JSON.parse(userStorage)
    if(userStorage?.promotora == true){
      setIsPromotora(true)
      const response = await api.get('/pontuacao/saldo/promotora');
      setSaldoPromotora(response?.data?.saldo)
    }else{
      setIsPromotora(false)
    }
  }

  return !user ? (
    <>
      {!user &&
        <>
          <header className="header-container">
            <div className="red-stripe">
              <TesteFlag />
              {isTabletSize && showLogin &&
                <>
                  <span style={{ color: '#FFFF', marginRight: '10px', marginLeft: '10px', fontSize: 12 }}>Já se cadastrou?</span>
                  <Button
                    onClick={() => gotoLogin()}
                    color="#FFFF"
                    fontSize={12}
                    height="30px"
                    _hover={{ backgroundColor: "transparent" }}
                    backgroundColor="#EC1C24">
                    Entrar
                  </Button>
                  <span style={{ color: '#FFFF', marginRight: '5px', marginLeft: '5px', fontSize: 12 }}>ou</span>
                  <Button
                    onClick={() => gotoLogin()}
                    color="#FFFF"
                    fontSize={12}
                    height="30px"
                    _hover={{ backgroundColor: "transparent" }}
                    backgroundColor="#EC1C24"
                    marginRight="10px">
                    Cadastrar
                  </Button>
                </>
              }
            </div>
          </header>
          {!isTabletSize && showLogin &&
            <Flex className='container-login-topo' marginTop={process.env.REACT_APP_TESTE === 'true' ? '20px' : ''}>
              <LoginInput />
            </Flex>
          }
        </>
      }
    </>
  ) : (
    <Box>
      <Modal
        isOpen={openCep}
        onClose={() => setOpenCep(false)}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent maxWidth={isMobileSize ? "90%" : '40%'}>
          <ModalHeader padding={7}>
            Informe seu CEP para personalizarmos seu conteúdo
            <span style={{ fontSize: 12, color: 'rgba(0,0,0,.55)' }}>
              <br />
              Você poderá visualizar as lojas mais próximas do seu endereço.
            </span>
          </ModalHeader>
          <ModalCloseButton _focus={{border: 'none'}} />
          <ModalBody pb={6}>
            <span style={{ fontSize: 15, marginLeft: 5, fontWeight: 500, color: 'gray' }} >
              Código de Endereçamento Postal
            </span>
            <Flex padding={1} borderRadius={8} width={300} flexDirection="column">
              <InputMask
                style={{
                  width: 250,
                  paddingLeft: 10,
                  padding: 10,
                  border: '1px solid black',
                  borderRadius: 5,
                  fontSize: 16
                }}
                mask="99999-999"
                placeholder="Informar um cep"
                onChange={(e) => setCepValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    saveCep();
                  }
                }}
                value={cepValue}
              />
            </Flex>
          </ModalBody>
          <Flex alignItems={'flex-end'} justifyContent={'flex-end'} padding={5}>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => saveCep()}
              disabled={loading}
              minWidth={100}
            >
              {loading ?
                <Spinner color="white" />
                :
                'Salvar'
              }
            </Button>
          </Flex>

        </ModalContent>
      </Modal>

      <header
        onMouseEnter={() => setIsShowMenuList(false)}
        className='header-container-logged'
        style={{
          fontSize: 12,
          zIndex: 6,
          height: process.env.REACT_APP_TESTE === 'true' ? '75px' : '50px'
        }}
      >
        <div
          className="red-stripe"
          style={{
            color: "#FFF",
            fontSize: 12,
          }}>

          <TesteFlag isLogged={true}/>
          <div className="container-first-header">
            <div className="container-item">
                <div className="item-menu none-mobile">
                  <Flex
                    margin="0px auto"
                    paddingRight={isMobileSize ? "20px" : "0px"}
                    justifyContent="space-between"
                    alignItems="center"
                    maxWidth="1400px"
                    height={isMobileSize ? "50px" : "undefined"}
                  >
                    <Flex alignItems="center">
                      {!isMobileSize && (
                        <Text
                          marginTop={5}
                          marginLeft={2}
                          fontSize="12px"
                          fontWeight="bold"
                          color="#FFFF"
                        >{`Bem-vindo(a), ${user.nomeCompleto?.split(' ')[0]}`}</Text>
                      )}
                    </Flex>
                    {isPromotora &&
                      <Flex alignItems="center" cursor={"pointer"} onClick={() => navigate("/extrato-pontos")}>
                        {!isMobileSize && (
                          <Text
                            marginTop={5}
                            marginLeft={2}
                            fontSize="12px"
                            fontWeight="bold"
                            color="#FFFF"
                          >{`/ Saldo: ${saldoPromotora ?? '0'}`}</Text>
                        )}
                      </Flex>
                    }
                  </Flex>
                </div>
                <div className="item-menu">
                  <Flex alignItems="center" justifyContent={"center"}>
                    {!isMobileSize && (
                      <Button
                        onClick={() => setOpenCep(true)}
                        fontSize="12px"
                        fontWeight="regular"
                        height="25px"
                        color="#FFFF"
                        backgroundColor="#B40F15"
                        border="solid 1px #FFFF"
                        borderRadius={20}
                        _active={{ backgroundColor: "#B40F15" }}
                        _focus={{ backgroundColor: "#B40F15" }}
                        _hover={{ backgroundColor: "#E53E3E" }}
                        minWidth="50px"
                      >
                        <Image src={iconLocation} width={3.5} marginRight={1} marginLeft={-2} />
                        {loadingCep ?
                          <Spinner color="white" width={3} height={3} />
                          :
                          cep ?
                            (state ? state + ': ' : '') + cep
                            : 'Buscar CEP'
                        }
                      </Button>
                    )}
                    {isMobileSize && (
                      <MenuHeaderMobile
                        handleShowMenuList={handleShowMenuListMobile}
                        subCategories={subCategories}
                        categoryIdRef={categoryIdRef.current}
                        categoryNameRef={categoryNameRef.current}
                        categoriaSelecionada={categoriaSelecionada}
                      />
                    )}
                  </Flex>
                </div>
                <div className="item-menu">
                  <Button
                    onClick={() => navigate("/minha-conta")}
                    height="25px"
                    width="99px"
                    fontSize="12px"
                    fontWeight="regular"
                    color="#FFFF"
                    backgroundColor="#B40F15"
                    border="solid 1px #FFFF"
                    borderRadius={20}
                    _active={{ backgroundColor: "#B40F15" }}
                    _focus={{ backgroundColor: "#B40F15" }}
                    _hover={{ backgroundColor: "#E53E3E" }}
                  >
                    Minha conta
                  </Button>
                </div>
                <div className="item-menu">
                <InputGroup width={!isMobileSize ? "364px" : isMobileSizeMini ?  listPoints.length > 0 ? "96px" : "126px" : listPoints.length > 0 ? "136px" : "156px"}>
                    <InputLeftElement
                      children={
                        <Button
                          height="25px"
                          backgroundColor="transparent"
                          _active={{ backgroundColor: "transparent" }}
                          _focus={{ backgroundColor: "transparent" }}
                          _hover={{ backgroundColor: "transparent" }}
                          onClick={handleSendSearchProduct}
                        >
                          <SearchIcon
                            marginBottom={4}
                            _hover={{ opacity: 0.5 }}
                            color="#FFFF"
                          />
                        </Button>
                      }
                    />
                    <Input
                      textAlign="end"
                      height="25px"

                      fontSize="12px"
                      fontWeight="regular"
                      onChange={(e) => handleSearchProduct(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleSendSearchProduct();
                        }
                      }}
                      value={searchProduct}
                      border="solid 1px #FFFF"
                      borderRadius={30}
                      placeholder={ isMobileSize ? "Buscar" : "O que você busca?"}
                      _placeholder={{
                        fontSize: "12px",
                        fontWeight: "regular",
                        color: "#FFFF",
                      }}
                    />
                  </InputGroup>
                </div>
                {/**
                {listPoints.length > 0 &&
                  <div className="item-menu" style={{position: 'relative'}} onMouseLeave={() => setShowSaldo(false)} >
                      <Button
                        height="25px"
                        fontSize="12px"
                        fontWeight="bold"
                        color="#FFFF"
                        backgroundColor="#B40F15"
                        border="solid 1px #FFFF"
                        borderRadius={20}
                        _active={{ backgroundColor: "#B40F15" }}
                        _focus={{ backgroundColor: "#B40F15" }}
                        _hover={{ backgroundColor: "#B40F15" }}
                        onMouseEnter={() => setShowSaldo(true)}
                      >
                        $
                      </Button>
                    {showSaldo &&
                      <div className="container-saldo-pontos">
                        <div className="container-title">
                          <span>
                            Pontos por Marca
                          </span>
                        </div>
                        {listPoints.map((item: any, index) => {
                            return (
                              <div className="list-points" style={{borderBottom: index == listPoints.length -1 ? "none" : "1px solid rgba(0,0,0,0.1)"}}>
                                <div style={{display: 'flex', flex: 1, marginTop: 10, justifyContent: 'flex-start', alignItems: 'center'}}>
                                  <span className="title">
                                    {item.nome}
                                  </span>
                                </div>
                                <div style={{display: 'flex', flex: 1, marginTop: 10,  alignItems: 'center', justifyContent: 'flex-end'}}>
                                  <span className="subtitle">
                                    {formattingNumberWithoutDecimals(item.pontos)} pontos
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        )}
                      </div>
                    }
                  </div>
                }
                 */}
                {isMobileSize && (
                  <button className="item-menu" style={{position: 'relative'}} onClick={() => navigate('/carrinho')}>
                    <>
                      <div style={{width: 40, borderRadius: 16, border: '1px solid white', height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={cart} style={{width: 16, height: 16}} />
                      </div>
                      <div style={{position: 'absolute', width: 15, height: 15, borderRadius: 9999, right: -0, top: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff'}}>
                        <span style={{color: '#EC1C24', fontSize: 10, fontWeight: 'bold'}}>
                          {quantityCart}
                        </span>
                      </div>
                    </>
                  </button>
                )}
                <div className="item-menu">
                  {!isMobileSize && (
                    <Button
                      onClick={() => navigate('/carrinho')}
                      height="25px"

                      fontSize="12px"
                      fontWeight="regular"
                      color="#FFFF"
                      backgroundColor="#B40F15"
                      border="solid 1px #FFFF"
                      borderRadius={20}
                      _active={{ backgroundColor: "#B40F15" }}
                      _focus={{ backgroundColor: "#B40F15" }}
                      _hover={{ backgroundColor: "#B40F15" }}
                    >
                      Meu carrinho ({quantityCart})
                    </Button>
                  )}
                </div>
                <div className="item-menu">
                  {!isMobileSize && (
                    <Button
                      onClick={handleLogout}
                      height="25px"
                      width="80px"

                      fontSize="12px"
                      fontWeight="regular"
                      color="#FFFF"
                      backgroundColor="#B40F15"
                      border="solid 1px #FFFF"
                      borderRadius={20}
                      _active={{ backgroundColor: "#B40F15" }}
                      _focus={{ backgroundColor: "#B40F15" }}
                      _hover={{ backgroundColor: "#B40F15" }}
                    >
                      Sair
                    </Button>
                  )}
                </div>
            </div>
          </div>
        </div>
      </header>
      {!isMobileSize && (
        <SubHeader
          isShowMenuList={isShowMenuList}
          setIsShowMenuList={setIsShowMenuList}
          handleShowMenuList={handleShowMenuList}
          categoryIdRef={categoryIdRef.current}
          categories={categories}
        />
      )}

      {isShowMenuList && (
        <CategoriesList
          categoryRefName={categoryNameRef.current}
          setIsShowMenuList={setIsShowMenuList}
          subCategories={subCategories}
          categoriaSelecionada={categoriaSelecionada}
          isCategoryOff={isCategoryOff}
        />
      )}
    </Box>
  );
};
