import { useEffect } from "react";
import { Text, Flex, Button, useMediaQuery } from "@chakra-ui/react";
import { FiChevronUp } from "react-icons/fi";

interface ScrollToTopProps {
  showTopBtn: boolean;
  color: string;
}

export const ScrollToTop = ({ showTopBtn, color }: ScrollToTopProps) => {
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showTopBtn && (
        <Flex
          justifyContent={isMobileSize ? "center" : "flex-end"}
          width="100%"
          marginBottom={-4}
        >
          <Button
            zIndex={1}
            color={color}
            textAlign="center"
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            _active={{ backgroundColor: "transparent" }}
            _focus={{ backgroundColor: "transparent" }}
            onClick={goToTop}
          >
            <Flex alignItems="center" cursor="pointer">
              <Text
                onClick={goToTop}
                fontSize="14px"
                fontWeight="bold"
              >
                Voltar ao topo
              </Text>
              <FiChevronUp
                style={{ marginBottom: 20 }}
                color={color}
                size={30}
              />
            </Flex>
          </Button>
        </Flex>
      )}
    </>
  );
};
