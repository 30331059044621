import { useToast } from "@chakra-ui/react";

interface Props {
  messageError: string;
  status: "error" | "success" | "warning" | "info";
  description?: string;
  position?: "bottom" | "bottom-end" | "bottom-left" | "bottom-right" | "bottom-start" | "top" | "top-end" | "top-left" | "top-right" | "top-start";
  duration?: number;
}

export function useCustomToast() {
  const toast = useToast();

  function getToast({ messageError, description, status, position = "top-right", duration = 4000 }: Props) {
    toast({
      title: messageError,
      description: description,
      status,
      duration: duration,
      isClosable: true,
      position: position,
      containerStyle: {
        minHeight: 80,
        marginTop: 5,
        width: '50%'
      },
    });
  }

  return { getToast };
}
