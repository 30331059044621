import { Flex, Text, useMediaQuery } from "@chakra-ui/react";

interface RenderContentProducts {
  produto: string;
  quantidade: number;
  valor: string;
}

export const RenderRowContentProducts = ({
  produto,
  quantidade,
  valor,
}: RenderContentProducts) => {
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");

  return (
    <Flex
      width={isMobileSize ? "85%" : "95%"}
      alignItems="center"
      margin="0px auto"
    >
      <Flex marginRight="auto">
        <Text
          maxWidth={!isMobileSize ? 50 : "100%"}
          
          fontSize={isMobileSize ? "12px" : "16px"}
          fontWeight="normal"
          color="#B9B9B9"
        >
          {produto}
        </Text>
      </Flex>

      <Flex margin="0px auto">
        <Text
          
          fontSize={isMobileSize ? "12px" : "16px"}
          fontWeight="normal"
          color="#B9B9B9"
        >
          {isMobileSize ? `Qtd: ${quantidade}` : `Quantidade: ${quantidade}`}
        </Text>
      </Flex>
      <Flex>
        <Text
          
          fontSize={isMobileSize ? "12px" : "16px"}
          fontWeight="normal"
          color="#B9B9B9"
        >
          {valor}
        </Text>
      </Flex>
    </Flex>
  );
};
