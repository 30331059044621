import { useState, useEffect } from "react";
import { Flex, Heading, Spinner, useMediaQuery } from "@chakra-ui/react";
import { SecondHeader } from "components/SecondHeader";
//import { HeadingMyData } from "../../components/HeadingMyData";
import "./style.scss";
import { BottomSpace } from "components/BottomSpace";
import { ChevronDownIcon } from '@chakra-ui/icons'
import api from "services/api";
import { GoBack } from "components/goBack";

export default function Faq() {
    const [isMobileSize] = useMediaQuery("(max-width: 992px)");
    const [loading, setLoading] = useState(true)
    const [questions, setQuestions] = useState<any>([])

    const changeItem = async (value:number, selected:boolean, i: number) => {
        const data = questions.map((item: any, index: number) => {
          if (index === i) {
            return {...item, selected: !selected};
          }
          return item;
        });
        setQuestions(data)
    }

    useEffect(() => {
        getFaq()
    }, [])

    const getFaq = async () => {
      try {
        const { data } = await api.get(`/faq/listarAtivos`);
        if(data?.length > 0){
          const newData = data.map((item: any, index: number) => {
            return {...item, selected: false};
          });
          setQuestions(newData)
        }
      } catch (error) {
        setQuestions([])
      } finally {
        setLoading(false)
      }
     
    }
      
    return (
        <>
            <SecondHeader />
            <Flex
                width="100%"
                maxWidth="1420px"
                justifyContent="space-between"
                alignItems="baseline"
                marginLeft={'auto'}
            >
                {!isMobileSize ? 
                    <div style={{marginLeft: '7.5%', marginTop: 20}}> 
                        <GoBack />
                    </div>
                    : <></>
                }
                <Flex flexDirection="column" margin="20px 0px" />
            </Flex>
            <Flex width="100%" maxWidth="1420px" margin="0px auto" justifyContent="center" marginBottom={20} padding={5}>
                
                <Flex width="100%" maxWidth="900px" flexDirection={'column'} >
                  <Heading
                    fontSize="40px"
                    fontWeight="bold"
                    color="#10125A"
                    textAlign={isMobileSize ? 'center' : 'left'}
                  >
                      Perguntas frequentes
                    </Heading>

                    {loading ?
                      <div style={{height: 400, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <Spinner color="#EC1C24" />
                      </div>
                      :
                      <main className="main-content" style={{padding: 0, marginTop: 70}}>
                          <div className="main-content-inner">
                          <div >
                            <div className="faq-container" style={{}}>
                            {questions?.map((item:any, index:number) => {
                              return (
                                <div className="faq-item" key={index}>
                                  <button className={"faq-select " + (item?.selected ? 'is-open' : '')} onClick={() => changeItem(item?.saldo, item?.selected, index)}>
                                    <div style={{flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                        <span style={{fontSize: 18, textAlign: 'left', fontFamily: 'Montserrat', fontWeight: 600, color: '#10125A'}}>{item?.pergunta}</span> 
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <ChevronDownIcon className="select-icon"/>
                                    </div>
                                  </button>
                                  <div className={'faq-item-subitem ' + (item?.selected ? 'is-open' : '')}>
                                    <span style={{fontSize: 15, textAlign: 'left', fontFamily: 'Montserrat', fontWeight: 400}}>
                                      {item?.resposta}  
                                    </span> 
                                  </div>
                                </div>
                              )})}
                            </div>
                          </div>
                          </div>
                      </main>
                    }
                </Flex>
            </Flex>
            <BottomSpace />
        </>
    );
};
