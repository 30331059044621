import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

//import homeReducer from "../features/home/HomeSlice";

const homeReducer = {};

export const store = configureStore({
  reducer: {
    home: homeReducer as any,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
