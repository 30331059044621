import { useNavigate } from "react-router-dom";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ISubCategory } from "interfaces/categories/ICategory";

interface CategoriesListProps {
  subCategories: ISubCategory[];
  setIsShowMenuList: (value: boolean) => void;
  categoryRefName: any;
  categoriaSelecionada: any;
  isCategoryOff?: boolean
}

interface SubCategoryProps extends ISubCategory {
  subCategoryChildrenName?: string;
  subCategoryChildrenId?: number;
  subCategoriaId?: number;
  categoriaSelecionadaId?: number;
  isChildren: boolean;
  uri?: string;
  uriPai?: string;
  uriFilho?: string;
  isCategoryOff?: boolean
}

export const CategoriesList = ({
  subCategories,
  setIsShowMenuList,
  categoryRefName,
  categoriaSelecionada,
  isCategoryOff = false
}: CategoriesListProps) => {
  const navigate = useNavigate();

  function handleCategory({
    uri,
    uriPai,
    uriFilho,
    categoriaSelecionadaId,
    descricao,
    categoriaId,
    categoriaPaiId,
    subCategoryChildrenName,
    subCategoryChildrenId,
    isChildren,
  }: SubCategoryProps) {
    setIsShowMenuList(false);

    var url = `/categoria/${uriPai}`;
    if (uri) { url += `/${uri}` }
    if (uriFilho) { url += `/${uriFilho}` }

    var subCategoriasSelected = subCategories.filter(x => x.uri === uri)
    var subs;

    if (subCategoriasSelected.length > 0){
      subs = subCategoriasSelected[0].subCategoriaFilho
    }
    
    navigate(url, {
    // navigate(`/categoria`, {
      state: {
        uri: uri,
        uriPai: uriPai,
        uriFilho: uriFilho,
        categorias: subs,
        subCategoriesPai: categoriaSelecionada?.subCategoria,
        subCategories: subs,
        categoriaSelecionadaId,
        categoriaSelecionadaNome: descricao,
        categoriaSelecionadaPai: categoriaSelecionada?.descricao
        // categoriaId: subCategoryChildrenId
        //   ? subCategoryChildrenId
        //   : categoriaPaiId,
        // categoriaName: categoryRefName,
        // subCategoryName: descricao,
        // subCategoryId: categoriaId,
        // subCategoryChildrenName,
        // subCategoryChildrenId,
        // cleanSubCategoryId: true,
        // isChildren,
      },
    });
  }

  return (
    <Flex position="relative" maxWidth="1420px" margin="0px auto" zIndex={10}>
      <Flex
        flexWrap="wrap"
        width="70%"
        maxHeight={'400px'}
        margin="0px auto"
        position="absolute"
        left="50%"
        top="50%"
        padding="20px 30px 40px 30px"
        transform="translate(-50%, -20px)"
        justifyContent="space-between"
        backgroundColor="#FFFF"
        border="solid 1px #707070"
        style={{overflowY: 'auto', marginTop: 20}}
        sx={{
          "&::-webkit-scrollbar-thumb": {
            background: "#10125A",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "1px",
          },
        }}
        onMouseLeave={() => setIsShowMenuList(false)}
      >
        {isCategoryOff ?
          <>
            <Flex key={1} flexDirection="column" width="250px">
              <Box>
                <Text
                  fontSize="15px"
                  fontWeight="semibold"
                  marginBottom="2px"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    navigate('/resgate')
                  }}
                >
                  &#45; Usar pontos
                </Text>
              </Box>
            </Flex>
            <Flex key={1} flexDirection="column" width="250px">
              <Box>
                <Text
                  fontSize="15px"
                  fontWeight="semibold"
                  marginBottom="2px"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    navigate('/ativos')
                  }}
                >
                  &#45; Produtos ativos
                </Text>
              </Box>
            </Flex>
            <Flex key={1} flexDirection="column" width="250px">
              <Box>
                <Text
                  fontSize="15px"
                  fontWeight="semibold"
                  marginBottom="2px"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    navigate("/extrato-de-pontos");
                  }}
                >
                  &#45; Extrato de pontos
                </Text>
              </Box>
            </Flex>
          </>
        :
        <>
        {!!subCategories?.length &&
          subCategories.map(
            (
              { categoriaId, descricao, categoriaPaiId, subCategoriaFilho, uri, uriPai },
              index
            ) => {
              return (
                <Flex key={index} flexDirection="column" width="250px">
                  <Box>
                    <Text
                      fontSize="15px"
                      fontWeight="semibold"
                      marginBottom="2px"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        handleCategory({
                          uri,
                          uriPai,
                          descricao,
                          categoriaSelecionadaId: categoriaId,
                          categoriaPaiId,
                          isChildren: true
                        });
                      }}
                    >
                      &#45; {descricao} 
                    </Text>
                    {!!subCategoriaFilho?.length &&
                      subCategoriaFilho?.slice(0, 3).map((item, index) => {
                        return (
                          <Box key={index} marginBottom={2}>
                            <Box
                              cursor="pointer"
                              onClick={() => {
                                handleCategory({
                                  uriFilho: item.uri,
                                  uri : item.uriPai,
                                  uriPai,
                                  descricao,
                                  categoriaSelecionadaId: item.categoriaId,
                                  categoriaPaiId,
                                  subCategoryChildrenName: item.descricao,
                                  subCategoryChildrenId: item.categoriaId,
                                  isChildren: true,
                                });
                              }}
                            >
                              <Text
                                fontSize="15px"
                                fontWeight="regular"
                                color="#10125A"
                                paddingLeft="10px"
                                marginBottom="5px"
                              >
                                {item.descricao}
                              </Text>
                            </Box>
                            {subCategoriaFilho?.length > 3 && index === 2 && (
                              <Box
                                color="#EC1C24"
                                cursor="pointer"
                                onClick={() => {
                                  handleCategory({
                                    uriFilho: item.uri,
                                    uri : item.uriPai,
                                    uriPai,
                                    descricao,
                                    categoriaSelecionadaId: item.categoriaId,
                                    categoriaPaiId,
                                    subCategoryChildrenName: "",
                                    subCategoryChildrenId: undefined,
                                    isChildren: false,
                                  });
                                }}
                              >
                                <Text
                                  fontSize="15px"
                                  fontWeight="regular">
                                  + ver mais
                                </Text>
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                  </Box>
                </Flex>
              );
            }
          )}
          </>
        }
      </Flex>
    </Flex>
  );
};
