import { extendTheme } from "@chakra-ui/react";
import '../assets/fonts/stylesheet.css';

export const theme = extendTheme({
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  styles: {
    global: {
      "body, html, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video":
        {
          margin: 0,
          padding: 0,
          border: 0,
          boxSizing: "border-box",
          font: "inherit",
          fontSize: "100%",
          fontFamily: "Montserrat",
          verticalAlign: "baseline",
        },
      "article, aside, details, figcaption, figure, footer, footer, header, hgroup, menu, nav, section":
        {
          display: "block",
        },
      html: {
        height: "100%",
      },
      body: {
        lineHeight: 1,
        minHeight: "100%",
      },
      "ol, ul": {
        listStyle: "none",
      },
      "blockquote, q": {
        quotes: "none",
      },
      "blockquote::before, blockquote::after, q::before, q::after": {
        content: "''",
      },
      table: {
        borderCollapse: "collapse",
        borderSpacing: 0,
      },
      img: {
        display: "block",
      },
      strong: {
        fontWeight: "bold",
      },
      div: {
        fontSize: "1.3rem",
        fontWeight: 300,
      },
      p: {
        marginBottom: "20px",
      },
      h1: {
        fontSize: "50px",
        fontWeight: "bold",
      },
      a: {
        textDecoration: "none",
      },
      ".slider-container": {
        ".slick-prev,.slick-next": {
          display: "none",
          width: "auto",
          height: "280px",
          "&::before": {
            content: "none",
          },
        },
      },
      ".text-blue": {
        color: "#10125A !important",
      },
      ".text-blue-light": {
        color: "#003591 !important",
      },
      ".text-red": {
        color: "#ec1c24 !important",
      },
      ".text-white": {
        color: "#fff !important",
      },
      ".bg-blue": {
        backgroundColor: "#10125A",
      },
      ".bg-red": {
        backgroundColor: "#ec1c24",
      },
      ".bg-gray": {
        backgroundColor: "#ededed",
      },
      ".arrow-red": {
        svg: {
          fill: "#ec1c24",
        },
        ".arrow-blue": {
          svg: {
            fill: "#10125A",
          },
        },
      },
    },
  },
});
