import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";

interface GoogleReCAPTCHAProps extends ReCAPTCHAProps {
  handleChangeReCAPTCHA: (token: string | null) => void;
  handleErroredReCAPTCHA?: () => void;
  handleExpiredReCaptcha?: () => void;
  captchaRef: any;
  isInvisible?: boolean;
}

export const GoogleReCAPTCHA = ({
  handleChangeReCAPTCHA,
  handleErroredReCAPTCHA,
  handleExpiredReCaptcha,
  captchaRef,
  isInvisible = false,
}: GoogleReCAPTCHAProps) => {
  const siteKey = isInvisible
    ? "6LcxBHciAAAAALtVFT217nIIFkN6ZKhz5qZIukX_"
    : "6LdR_C8iAAAAADmc32FdMbysqGmAzldns-GNA2zc";
  return (
    <ReCAPTCHA
      ref={captchaRef}
      sitekey={siteKey ?? ""}
      onChange={handleChangeReCAPTCHA}
      onErrored={handleErroredReCAPTCHA}
      onExpired={handleExpiredReCaptcha}
      size={isInvisible ? "invisible" : "normal"}
    />
  );
};
