import { MenuList, MenuItem, Flex, Box, Text } from "@chakra-ui/react";
import { ISubCategory } from "interfaces/categories/ICategory";

interface MenuItemHeaderProps {
  subCategories: ISubCategory[];
  handleCategory: any;
  handleCloseMenu?: () => void;
}

export const MenuItemHeader = ({
  subCategories,
  handleCategory,
  handleCloseMenu,
}: MenuItemHeaderProps) => (
  <MenuList width="100%" height="100%">
    {!!subCategories?.length &&
      subCategories.map(
        (
          { categoriaId, descricao, categoriaPaiId, subCategoriaFilho, uri, uriPai },
          index
        ) => (
          <Flex key={index} overflowY="scroll" width="100%" minWidth="319px">
            <MenuItem
              flexWrap="wrap"
              flex="1 1 50px"
              marginLeft="30px"
              padding={0}
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              _focus={{ backgroundColor: "transparent" }}
              lineHeight={1}
            >
              <Flex flexDirection="column">
                <button style={{border: 'none', width: 280}} onClick={() => {
                  handleCategory({
                    uri,
                    uriPai,
                    descricao,
                    categoriaSelecionadaId: categoriaId,
                    categoriaPaiId,
                    isChildren: true
                  })
                }}>
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    color="#10125A"
                    textAlign={"left"}
                  >
                    {descricao}
                  </Text>
                </button>

                {!!subCategoriaFilho?.length &&
                  subCategoriaFilho?.slice(0, 3).map((item, index) => {
                    return (
                      <Box key={index}>
                        <Box
                          key={index}
                          cursor="pointer"
                          onClick={() => {
                            handleCategory({
                              uriFilho: item.uri,
                              uri : item.uriPai,
                              uriPai,
                              descricao,
                              categoriaSelecionadaId: item.categoriaId,
                              categoriaPaiId,
                              subCategoryChildrenName: item.descricao,
                              subCategoryChildrenId: item.categoriaId,
                              isChildren: true,
                            });
                            handleCloseMenu && handleCloseMenu();
                          }}
                        >
                          <Text
                            
                            fontSize="18px"
                            fontWeight="regular"
                            color="#10125A"
                          >
                            {item.descricao}
                          </Text>
                        </Box>
                        {subCategoriaFilho?.length > 3 && index === 2 && (
                          <Box
                            color="#EC1C24"
                            cursor="pointer"
                            onClick={() => {
                              handleCategory({
                                uriFilho: item.uri,
                                uri : item.uriPai,
                                uriPai,
                                descricao,
                                categoriaSelecionadaId: item.categoriaId,
                                categoriaPaiId,
                                subCategoryChildrenName: "",
                                subCategoryChildrenId: undefined,
                                isChildren: false,
                              });
                              handleCloseMenu && handleCloseMenu();
                            }}
                          >
                            <Text fontSize="16px" fontWeight="regular">
                              + ver mais
                            </Text>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Flex>
            </MenuItem>
          </Flex>
        )
      )}
  </MenuList>
);
