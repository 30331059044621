import {
  Box,
  Flex,
  Link,
  Input,
  useMediaQuery,
  Text,
  Button,
  useDisclosure,
  propNames,
} from "@chakra-ui/react";
import { useAuth } from "hooks/useAuth";
import { useCustomToast } from "hooks/useToast";
import { ChangeEvent, useState } from "react";
//import { GoogleReCAPTCHA } from "components/GoogleReCAPTCHA";
import InputMask from "react-input-mask";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { Modal } from "components/Modal";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
interface LoginInputProps {
  // setDocumento: (event: string) => void;
  // setPassword: (event: string) => void;
  // handleLogin: () => void;
  // handleSignUp: () => void;
  // handleChange: any;
  // onOpen: () => void;
  // cpfSignup: string;
  // handleChangeReCAPTCHA: (token: string | null) => void;
  // handleExpiredReCaptcha?: () => void;
  // captchaRef: any;
  // loading?: boolean;
  // ref?: any
}

//const siteKey = "6LcxBHciAAAAALtVFT217nIIFkN6ZKhz5qZIukX_";

export const LoginInput = () => {
  // }) => {
  const [cpfSignup, setCpfSignup] = useState("");
  const [documento, setDocumento] = useState("");
  const [password, setPassword] = useState("");
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const { signIn, signInFacebook } = useAuth();
  const { getToast } = useCustomToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [loading, setLoading] = useState(false);
  let isLogin = false;
  const navigate = useNavigate();

  const [showPass, setShowPass] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCpfSignup(event.target.value);
  };

  const handleLogin = async () => {
    if (!documento || !password) return;

    try {
      //if (!token && !user) return;

      setLoading(true);
      const data = await signIn({
        login: documento,
        senha: password,
      });
      setLoading(false);
      isLogin = false;
      if(data.cadastroId){
        navigate("/valida-cadastro", { state: data });
      }else{
        localStorage.setItem('@DClube:password', password);
        navigate("/home", { state: cpfSignup });
      }
    } catch (error: any) {
      console.log(error);
      getToast({
        messageError: "Login incorreto!",
        description: "Favor verificar os seus dados",
        status: "error",
      });
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    localStorage.setItem("@DClube:dataFacebook", '');
    if (!cpfSignup) return;
    try {
      const response = await api.get(`/cadastro/${cpfSignup}`);
      if (response?.data?.existe) {
        /*
        if(response?.data?.codigoFacebook){
          getToast({ messageError: 'CPF já vinculado a outro e-mail, faça seu login com e-mail e senha e vincule sua conta do Facebook em "meus dados"', status: 'info'});
          return
        }
        */
        getToast({ messageError: response?.data?.mensagem, status: "info" });
      } else {
        navigate("/cadastro", { state: cpfSignup });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginFacebookSucess = async (data: any) => {

    const response = await fetch(`https://graph.facebook.com/v2.5/me?fields=email,name,first_name,last_name,birthday&access_token=${data?.accessToken}`);
    let result = await response.json();
    try {
      setLoading(true);
      const data = await signInFacebook({
        codigoFacebook: result.id,
        emailFacebook: result.email,
        origem: 'site'
      });

      setLoading(false);
      isLogin = false;
      if(data.cadastroId){
        navigate("/valida-cadastro", { state: data });
      }else{
        localStorage.setItem('@DClube:password', "@9@PrimeiroAcesso@9@");
        navigate("/home", { state: cpfSignup });
      }

    } catch (error: any) {
      localStorage.setItem("@DClube:dataFacebook", JSON.stringify(result));
      navigate("/cadastro", { state: cpfSignup});
    }
  }
  
  return (
    <Flex className="container-login" id="login">
      <Modal
        title="Um link de recuperação de senha será enviado ao e-mail cadastrado"
        isInput
        titleInput="E-mail"
        placeholderInputText="Digite seu e-mail"
        buttonSubmitText="Enviar"
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      />
      <Flex className="login">
        <Flex className="form">
          <Text className="label">
            Já se cadastrou?
          </Text>
          <Flex className="form-login" style={{ width: '100%',  flexDirection: isMobileSize ? 'column': 'row', alignItems: 'flex-start'}}>
            <div style={{flex: 1, position: 'relative', flexDirection: 'row', width: '100%'}}>
              <InputMask
                type="text"
                placeholder="CPF"
                onChange={(e) => setDocumento(e.target.value)}
                mask="999.999.999-99" style={{height: 40, marginRight: 5, width: isMobileSize ? '100%' : 200}} />
            </div>
            <div style={{flex: 1, position: 'relative',  maxHeight: 40, width: '100%', flexDirection: 'row', marginLeft: isMobileSize ? 0 : 5, marginRight: isMobileSize ? 0 : 15, border: "solid 1px transparent"}}>
              <Input style={{width: isMobileSize ? '100%' : 200, maxHeight: 40, borderRadius: 5, backgroundColor: "#FFF", fontSize: 12}}
                type={showPass ? "string" : "password"}
                onChange={(e: any) => setPassword(e.target.value)}
                placeholder="Senha"
                marginRight="15px"
                />
                {showPass ? 
                  <ViewIcon onClick={() => setShowPass(!showPass)} aria-label="decrescente" style={{backgroundColor: 'white', zIndex: 9, color: '#424242', cursor: 'pointer', position: 'absolute', bottom: 8, right: 30}} />
                  :
                  <ViewOffIcon onClick={() => setShowPass(!showPass)} aria-label="decrescente" style={{backgroundColor: 'white', zIndex: 9, color: '#424242', cursor: 'pointer', position: 'absolute', bottom:  8, right: 30}} />
                }
            </div>
            <Button
              onClick={handleLogin}
              color="#FFFF"
              backgroundColor="#EC1C24"
              marginRight="50px"
              isLoading={loading} style={{marginTop: isMobileSize ? 20 : 0}}>
              Entrar
            </Button>
          </Flex>
          <Box as={Link} style={{display: 'flex', flexDirection: 'row'}}>
            <Text
              fontSize="12px"
              marginTop="10px"
              color="#FFFF" onClick={onOpen}>
              Esqueceu sua senha?
            </Text>
          </Box>
        </Flex>

        <Flex className="form cadastro">
          <Text className="label">
            Não se cadastrou? Cadastre-se já!
          </Text>
          <Flex>

            <InputMask
              onChange={handleChange}
              type="text"
              mask="999.999.999-99"
              value={cpfSignup}
              placeholder="CPF" />
            <Button
              onClick={handleSignUp}
              color="#FFFF"
              backgroundColor="#EC1C24"
              marginLeft="50px">
              OK
            </Button>
          </Flex>
          <div style={{display: 'flex', paddingBottom: 10, paddingTop: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <FacebookLogin
              appId="2590165284477787"
              onSuccess={(response) => {
                loginFacebookSucess(response)
              }}
              onFail={(error) => {
                console.log('Login Failed!', error);
              }}
              onProfileSuccess={(response) => {
                console.log('Get Profile Success!', response);
              }}
              render={renderProps => (
                <button style={{ height: 40, borderRadius: 5, fontWeight: 'bold', color: 'white', fontSize: 12, marginLeft: 20, backgroundColor: '#4267B2'}}  onClick={renderProps.onClick}>
                  Entrar com facebook
                </button>
              )}
            />
          </div>
        </Flex>
        {/* <GoogleReCAPTCHA
            lang="pt-BR"
            isInvisible
            captchaRef={captchaRef}
            handleChangeReCAPTCHA={handleChangeReCAPTCHA}
            sitekey={siteKey}
            handleExpiredReCaptcha={handleExpiredReCaptcha}
          /> */}
      </Flex>
    </Flex>
  );
};
