import React, { createContext, useReducer } from 'react';

const Reducer = (state, action) => {
  const resetStates = {
    quantidade: 0,
    products: []
  };
  switch (action.type) {
    case ('UPDATE_QUANT_CARRINHO'):
      return {
        ...state,
        ...resetStates,
        quantidade: action.quantidade,
        products: action.products
      };
    default:
      console.log('action type',state);
      return state;
  }
};

const initialState = {
  quantidade: 0,
  products: []
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
export default Store;
