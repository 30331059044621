import { useNavigate } from "react-router-dom";
import { Flex, Image, Text } from "@chakra-ui/react";
import { AiOutlineHome } from "react-icons/ai";

import menuIcon from "assets/svg/menu.svg";
import bebidasIcon from "assets/svg/bebidas.svg";
import alimentosIcon from "assets/svg/alimentos.svg";
import beneficiosIcon from "assets/svg/beneficios.svg";
import higieneESaudeIcon from "assets/svg/higiene-e-saude.svg";
import promocoesIcon from "assets/svg/promocoes.svg";
import packageIcon from "assets/icons/package.png";
import boxIcon from "assets/icons/box.png";
import { useContext, useEffect, useState } from "react";
import api from "services/api";
import { Context as ContextStore } from '../../../contexts/store';
import { Context as  ContextCart } from '../../../contexts/cart';
import { Context as  ContextActivated } from '../../../contexts/activated';

import TimerCount from "./timer";

interface SubCategoryProps {
  categoriaId?: number;
  id?: number;
  descricao: string;
  uri: string;
}

interface CategoryProps {
  id: number;
  descricao: string;
  uri: string;
  subCategoria: SubCategoryProps[];
}

interface SubHeaderProps {
  isShowMenuList: boolean;
  handleShowMenuList: (categoryId: number, descricao?: any) => void;
  setIsShowMenuList: (value: boolean) => void;
  categoryIdRef: any;
  categories: CategoryProps[];
}

export const SubHeader = ({
  isShowMenuList,
  handleShowMenuList,
  setIsShowMenuList,
  categoryIdRef,
  categories,
}: SubHeaderProps) => {

  const navigate = useNavigate();
  const [dateExpire, setDateExpire] = useState<any>('');
  const [stateStore, dispatchStore] = useContext<any>(ContextStore);
  const [stateCart, dispatchCart] = useContext<any>(ContextCart);
  const [stateActivated, dispatchActivated] = useContext<any>(ContextActivated);
  const [quantityDiscount, setQuantityDiscount] = useState<any>(0);

  useEffect(() => {
    verifyHaveCartActive()
  }, [])

  useEffect(() => {
    setQuantityDiscount(stateActivated.quantidade)
  }, [stateActivated])

  const verifyHaveCartActive = async () => {
    let { data } = await api.get("/motorDePontos/consultarCarrinhoAtivo");
    if(data.length > 0){
      dispatchCart({ type: "CHANGE_SHOW_CART", isShow: false})
      dispatchActivated({ type: "UPDATE_QUANT_ACTIVATED", quantidade: data.length})
      setQuantityDiscount(data.length)
    }else{
      dispatchActivated({ type: "UPDATE_QUANT_ACTIVATED", quantidade: 0})
    }
  }

  function getDescriptionCategory(id: number) {
    if (!id) return;
    const category = categories.find((category) => category.id === id);
    if (!category) return;
    return category?.descricao;
  }

  function getCategory(id: number) {
    if (!id) return;
    const category = categories.find((category) => category.id === id);
    if (!category) return;
    return category;
  }

  return (
    <Flex className="container-menu">
      <Flex className="menu">
        <Flex className="menu-item"
          onMouseEnter={() => { setIsShowMenuList(false) }}
          onClick={() => {
            navigate("/home");
          }}>
          <AiOutlineHome fontSize={40} />
          <Text className="menu-text">
            Home
          </Text>
        </Flex>
        <Flex className="menu-item"
          onMouseEnter={() => {
            handleShowMenuList(0);
            setIsShowMenuList(true);
          }}>
          <Image className="menu-image" src={menuIcon} />
          <Text className="menu-text">
            Todas
          </Text>
        </Flex>

        <Flex className="menu-item"
          onMouseEnter={() => {
            handleShowMenuList(29, getDescriptionCategory(29));
            var categoria = getCategory(29)?.subCategoria ?? []
            if(categoria.length > 0){
              setIsShowMenuList(true);
            }else{
              setIsShowMenuList(false);
            }
          }}
          onClick={() => {
            setIsShowMenuList(false)
            var categoria = getCategory(29)
            navigate(`/categoria/${categoria?.uri}`, {
              state: {
                uriPai: categoria?.uri,
                categoriaSelecionadaId: categoria?.id,
                categoriaSelecionadaPai: categoria?.descricao,
                subCategoriesPai: categoria?.subCategoria
              }
            })
          }}
        >
          <Image className="menu-image" src={bebidasIcon} />
          <Text className="menu-text">
            Bebidas
          </Text>
        </Flex>

        <Flex className="menu-item"
          onMouseEnter={() => {
            handleShowMenuList(1, getDescriptionCategory(1));
            var categoria = getCategory(1)?.subCategoria ?? []
            if(categoria.length > 0){
              setIsShowMenuList(true);
            }else{
              setIsShowMenuList(false);
            }
          }}
          onClick={() => {
            setIsShowMenuList(false)

            var categoria = getCategory(1)
            navigate(`/categoria/${categoria?.uri}`, {
              state: {
                uriPai: categoria?.uri,
                categoriaSelecionadaId: categoria?.id,
                categoriaSelecionadaPai: categoria?.descricao,
                subCategoriesPai: categoria?.subCategoria
              }
            })
          }}
        >
          <Image className="menu-image" src={alimentosIcon} />
          <Text className="menu-text">
            Alimentos
          </Text>
        </Flex>

        <Flex className="menu-item"
          onClick={() => {
            setIsShowMenuList(false)

            var categoria = getCategory(85)
            navigate(`/categoria/${categoria?.uri}`, {
              state: {
                uriPai: categoria?.uri,
                categoriaSelecionadaId: categoria?.id,
                categoriaSelecionadaPai: categoria?.descricao,
                subCategoriesPai: categoria?.subCategoria
              }
            })
          }}
          onMouseEnter={() => {
            handleShowMenuList(85, getDescriptionCategory(85));
            var categoria = getCategory(85)?.subCategoria ?? []
            if(categoria.length > 0){
              setIsShowMenuList(true);
            }else{
              setIsShowMenuList(false);
            }
          }}>
          <Image className="menu-image" src={higieneESaudeIcon} />
          <Text className="menu-text">
            Higiene e saúde
          </Text>
        </Flex>

        <Flex className="menu-item"
          onMouseEnter={() => { setIsShowMenuList(false) }}
          onClick={() => navigate("/beneficios")}>
          <Image className="menu-image" src={promocoesIcon} />
          <Text className="menu-text">
            Benefícios
          </Text>
        </Flex>

        {/* MENU DE PROMOÇÕES QUE VOLTARA QUANDO TIVER */}
        {/* <Flex className="menu-item"
          onMouseEnter={() => {setIsShowMenuList(false)}}
          onClick={() => navigate("/promocoes")}>
          <Image className="menu-image" src={promocoesIcon} />
          <Text className="menu-text">
            Promoções
          </Text>
        </Flex> */}

        <Flex className="menu-item"
          onMouseEnter={() => {setIsShowMenuList(false)}}
          onClick={() => navigate("/transacao")}>
          <Image className="menu-image" src={beneficiosIcon} />
          <Text className="menu-text">
            Minhas economias
          </Text>
        </Flex>
        <Flex className="menu-item"
          onClick={() => {
            navigate("/resgate")
            return setIsShowMenuList(false)
          }}
          onMouseEnter={() => {
            handleShowMenuList(9999);
            setIsShowMenuList(true);
          }}>
          <Image className="menu-image" src={packageIcon} />
          <Text className="menu-text">
            Meus pontos
          </Text>
        </Flex>
        {/** 
        <Flex className="menu-item" whiteSpace={"nowrap"} position={"relative"}
          onMouseEnter={() => { setIsShowMenuList(false) }}
          onClick={() => {
            navigate("/ativos")
          }}>
          <Image className="menu-image" src={boxIcon} />
          <Text className="menu-text">
            Produtos ativos
          </Text>
          {quantityDiscount > 0 &&
            <div className="container-number-activated" onClick={() => navigate("/ativos")}>
              <span className="text-number">
                {quantityDiscount}
              </span>
            </div>
          }
        </Flex>
        */}
      </Flex>
    </Flex>
  );
};
