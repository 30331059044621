export function checkDocumento(strCPF: string) {
  if (!strCPF) {
    return false;
  }
  let Soma;
  let Resto;
  Soma = 0;

  const documento = strCPF.replace(/[\W_]/gi, "");

  if (documento.split("").every((char) => char === documento[0])) return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(documento.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(documento.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(documento.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(documento.substring(10, 11))) return false;
  return true;
}

export function sugestEmailDomainAddress(email: string) {
  const possibleDomains: string[] = [
    "gmail.com",
    "outlook.com",
    "hotmail.com",
    "live.com",
    "yahoo.com",
    "ymail.com",
    "uol.com.br",
    "bol.com.br",
    "globomail.com",
    "icloud.com",
  ];
  const emailDomain = email?.split("@")[1] || "";

  if (
    !possibleDomains.filter((item) => item === emailDomain).length &&
    possibleDomains.filter((item) => item.includes(emailDomain))
  ) {
    return possibleDomains.slice(0, 5);
  }

  return [];
}

export function isEmail(email: string) {
  if (!email) return false;
  const RegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (RegEx.test(email.trim())) {
    return true;
  } else {
    return false;
  }
}

export const formValidation = (form: any, required: any[]) => {
  return Object.keys(required).filter((item) => {
    if (Object.keys(form).includes(item)) {
      if (
        form[item] === "" ||
        form[item] === undefined ||
        form[item] === null ||
        form[item].length === 0
      ) {
        return { error: true, field: item };
      }
    } else {
      return { error: true, field: item };
    }
  });
};

export const formattingNumberWithoutDecimals = (money: string) => {
  return parseInt(money).toLocaleString("pt-BR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formattingNumberWithDecimals = (money: number) => {
  const parsedValue = parseFloat(money.toString()).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `R$${parsedValue}`;
};

export const formattingNumberWithDecimalsAndWithoutCoin = (money: number) => {
  const parsedValue = parseFloat(money.toString()).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${parsedValue}`;
};

export const validadeStringSize = (string: string, maxLength: any) => {
  if (!string) {
    return;
  }
  if (string.length <= maxLength) {
    return string;
  } else {
    return string.slice(0, maxLength) + "...";
  }
};

export const fixDecimalsValues = (value: number) => {
  if (!value) return;
  const decimalpart = value - Math.floor(value);
  if (decimalpart !== 0) {
    return (decimalpart.toFixed(2) as any) * 100;
  }
  return "00";
};
