import axios from "axios";
import _get from "lodash/get";

const token = localStorage.getItem("@DClube:Admintoken") ?? localStorage.getItem("@DClube:token") ?? "";

export function authorizationInterceptor(config: any) {
  config.headers = config.headers || {};
  config.headers.Authorization = token;
  return config;
}

export function successToasterInterceptor(response: any) {
  if (response.config && response.config.cancelSuccessToaster) {
    return response;
  }
  if (response.config.method !== "get") {
  }
  return response;
}

export function errorInterceptor(error: any) {
  if (error && axios.isCancel(error)) {
    return Promise.reject(error);
  }
  const safeErrorMessage = _get(error, "response.data.errors[0].message");

  if (!error.response) {
    return Promise.reject(error);
  }

  if (error.response.status === 400) {
    //console.warn(safeErrorMessage);
  } else {
    console.error(safeErrorMessage);
  }
  return Promise.reject(error);
}

export function sessionTimeoutInterceptor(error: any | null) {
  if (error.isAxiosError) {
    const errorMessage =
      _get(error, "response.status") || _get(error, "message");

    console.log("errorMessage:", errorMessage);
  }

  if (error.response.status === 403 || error.response.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    return;
  }
  return Promise.reject(error);
}
