import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "layout";
import { Spinner } from "@chakra-ui/react";

import PrivateRoute from "./PrivateRoute";
import Contato from "pages/contato";
import Faq from "pages/faq";
import PublicRoute from "./PublicRoute";
import ResgatePage from "pages/resgate";
import CartButton from "components/Header/components/cartButton";
import CarrinhoPage from "pages/carrinho";
import DiscountActivatedPage from "pages/discount-activated";
import SegurosPage from "pages/seguros";
import ExtratoAtivacaoPage from "pages/extrato-ativacoes";

const HomePage = lazy(() => import("pages/home"));
const CredzPage = lazy(() => import("pages/credz"));
const LoginPage = lazy(() => import("pages/login"));
const CategoryPage = lazy(() => import("pages/categoria"));
const ProdutoDescricao = lazy(() => import("pages/produto-descricao"));
const BuscaGeral = lazy(() => import("pages/busca-geral"));
const MinhaContaPage = lazy(() => import("pages/minha-conta"));
const MeusDadosPage = lazy(() => import("pages/meus-dados"));
const MeusAmigos = lazy(() => import("pages/meus-amigos"));
const ExtratoPontos = lazy(() => import("pages/extrato-pontos"));
const Ranking = lazy(() => import("pages/ranking"));
const AlterarSenha = lazy(() => import("pages/alterar-senha"));
const TrabalheConosco = lazy(() => import("pages/trabalhe-conosco"));
const NossaHistoria = lazy(() => import("pages/nossa-historia"));
const NoQueAcreditamos = lazy(() => import("pages/no-que-acreditamos"));
const CadastroPage = lazy(() => import("pages/cadastro"));
const TransacaoPage = lazy(() => import("pages/transacao"));
const Promocoes = lazy(() => import("pages/promocoes"));
const EsqueciSenha = lazy(() => import("pages/esqueci-senha"));
const ValidaCadastro = lazy(() => import("pages/valida-cadastro"));
const AppPromotora = lazy(() => import("pages/app-promotora"));
const AgradecimentoValidacao = lazy(
  () => import("pages/agradecimento-validacao")
);
const Beneficios = lazy(() => import("pages/beneficios"));
const ErrorPage = lazy(() => import("pages/error"));
const PoliticaDePrivacidade = lazy(() => import("pages/politica-privacidade"));
const Regulamento = lazy(() => import("pages/regulamento"));
const TermosCondicoes = lazy(() => import("pages/termos-condicoes"));

export default function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        
        <Layout>
          <Routes>
            <Route path="/" element={<PublicRoute><LoginPage /></PublicRoute>} />
            <Route path="/cadastro/:idFriend" element={<PublicRoute><CadastroPage /></PublicRoute>} />
            <Route path="/cadastro" element={<PublicRoute><CadastroPage /></PublicRoute>} />
            <Route path="/esqueciSenha/:token" element={<PublicRoute><EsqueciSenha /></PublicRoute>} />
            <Route path="/valida-cadastro" element={<PublicRoute><ValidaCadastro /></PublicRoute>} />
            
            <Route path="/app-promotora/:idPromotora" element={<PublicRoute><AppPromotora /></PublicRoute>} />
            <Route path="/categoria/:categoriaPaiId/:categoriaId" element={<CategoryPage />} />
            <Route
              path="/agradecimento-validacao"
              element={<PublicRoute><AgradecimentoValidacao /></PublicRoute>}
            />
            <Route path="*" element={<ErrorPage />} />
            
            <Route path="/home" element={<PrivateRoute><CartButton><HomePage /></CartButton></PrivateRoute>} />
            <Route
              path="/categoria/:categoriaPaiId"
              element={
                <PrivateRoute>
                  <CartButton>
                    <CategoryPage />
                  </CartButton>
                </PrivateRoute>
              }
            />


            <Route
              path="/solicitar-cartao"
              element={
                <PrivateRoute>
                  <CartButton>
                    <CredzPage />
                  </CartButton>
                </PrivateRoute>
              }
            />

            <Route
              path="/categoria/:categoriaPaiId/:categoriaId/:categoriaFilhoId"
              element={
                <PrivateRoute>
                  <CartButton>
                    <CategoryPage />
                  </CartButton>
                </PrivateRoute>
              }
            />

            <Route
              path="/produto-descricao/:productSlug"
              element={
                <PrivateRoute>
                  <CartButton>
                    <ProdutoDescricao />
                  </CartButton>
                </PrivateRoute>
              }
            />

            <Route
              path="/busca-geral/:searchProductSlug"
              element={
                <PrivateRoute>
                  <CartButton>
                    <BuscaGeral />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/seguros"
              element={
                <PrivateRoute>
                  <CartButton>
                    <SegurosPage />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/seguros"
              element={
                <PrivateRoute>
                  <SegurosPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/minha-conta/"
              element={
                <PrivateRoute>
                  <CartButton>
                    <MinhaContaPage />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/meus-dados/"
              element={
                <PrivateRoute>
                  <CartButton>
                    <MeusDadosPage />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/meus-amigos"
              element={
                <PrivateRoute>
                  <CartButton>
                    <MeusAmigos />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/extrato-pontos"
              element={
                <PrivateRoute>
                  <CartButton>
                    <ExtratoPontos />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/extrato-de-pontos"
              element={
                <PrivateRoute>
                  <CartButton>
                    <ExtratoAtivacaoPage />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/ranking"
              element={
                <PrivateRoute>
                  <CartButton>
                    <Ranking />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/alterar-senha"
              element={
                <PrivateRoute>
                  <CartButton>
                    <AlterarSenha />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/transacao"
              element={
                <PrivateRoute>
                  <CartButton>
                    <TransacaoPage />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/resgate"
              element={
                <PrivateRoute>
                  <CartButton>
                    <ResgatePage />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/carrinho"
              element={
                <PrivateRoute>
                  <CartButton>
                    <CarrinhoPage />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/ativos"
              element={
                <PrivateRoute>
                  <CartButton>
                    <DiscountActivatedPage />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/promocoes"
              element={
                <PrivateRoute>
                  <CartButton>
                    <Promocoes />
                  </CartButton>
                </PrivateRoute>
              }
            />
            <Route
              path="/beneficios"
              element={
                <PrivateRoute>
                  <CartButton>
                    <Beneficios />
                  </CartButton>
                </PrivateRoute>
              }
            />

            <Route
              path="/trabalhe-conosco"
              element={
                <TrabalheConosco />
              }
            />

            <Route
              path="/nossa-historia"
              element={
                <NossaHistoria />
              }
            />

            <Route
              path="/no-que-acreditamos"
              element={
                <NoQueAcreditamos />
              }
            />

            <Route
              path="/contato"
              element={
                <Contato />
              }
            />

            <Route
              path="/faq"
              element={
                <Faq />
              }
            />

            <Route
              path="/politica-de-privacidade"
              element={<PoliticaDePrivacidade />}
            />
            <Route path="/regulamento" element={<Regulamento />} />
            <Route path="/termos-e-condicoes" element={<TermosCondicoes />} />
          </Routes>
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}
