import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

export const GoBack = ({

}) => {
  const navigate = useNavigate();

  function handleLink() {
    window.location.href="/"
  }

  return (
    <ChakraBreadcrumb
      fontSize="15px"
      separator={
        <Text margin={0} color="#EC1C24">
          {">"}
        </Text>
      }
      marginBottom={5}
    >
      <BreadcrumbItem onClick={handleLink}>
        <BreadcrumbLink
          fontSize="15px"
          href="/home"
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          _hover={{
            color: "#EC1C24",
            // fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          <ArrowBackIcon marginRight={2} fontWeight="bold"/>
          <span style={{fontWeight: 'bold'}}>
            Voltar
          </span>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </ChakraBreadcrumb>
  );
};
