import {
    Button
} from "@chakra-ui/react";
import "./style.scss";

export const TesteFlag = ({
    isLogged = false
}) => {

    if (process.env.REACT_APP_TESTE === 'false') return (null);

    const openInNewTab = (url: string) => {
        window.location.replace(url);
    };

    return (
        <div className="ambiente-teste" style={{margin : isLogged ? '0' : '-6px'}}>
            <span>Ambiente de Teste</span>
            <Button
                onClick={() => openInNewTab('https://dclube.com.br/')}
                color="#FFFF"
                fontSize={12}
                height="30px"
                _hover={{ backgroundColor: "#EC1C24" }}
                backgroundColor="#EC1C24">
                Acessar site
            </Button>
        </div>
    )
};
