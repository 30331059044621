import { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Image,
  useMediaQuery,
  Box,
  VStack,
  Text,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input
} from "@chakra-ui/react";

import empty from 'assets/icons/empty-cart.png';
import warning from 'assets/icons/warning.png';

import api from "services/api";
import { characterDelimiter } from "utils/characterDelimiter";
import { useCustomToast } from "hooks/useToast";
import { Context } from 'contexts/store';
import { Context as ContextCart } from 'contexts/cart';
import { Context as ContextActivated } from 'contexts/activated';
import { useAuth } from "hooks/useAuth";
import { formattingNumberWithDecimals } from "utils";
import "./style.scss";
import { Breadcrumb_v2 } from "components/Breadcrumb/index_v2";
import { useNavigate } from "react-router-dom";

const width = window.innerWidth

const CarrinhoPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const { getToast } = useCustomToast();
  const size = useWindowSize();
  const [originalProducts, setOriginalProducts] = useState([]);
  const [state, dispatch] = useContext(Context)
  const [stateActivated, dispatchActivated] = useContext(ContextActivated)
  const { dataUser } = useAuth();
  const [newValueQuantity, setNewValueQuantity] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentEan, setCurrentEan] = useState(0);
  const [showModalInput, setShowModalInput] = useState(false);
  const [totalPontos, setTotalPontos] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [alertSubtitle, setAlertSubtitle] = useState('...');
  const [alertTitle, setAlertTitle] = useState('...');
  const [alertShow, setAlertShow] = useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [carrinhoValido, setCarrinhoValido] = useState(true);
  const [currentErro, setCurrentErro] = useState(false);
  const [itemComMouse, setItemComMouse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getCart();
    getPoints();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }

  const handleMouseEnter = (index) => {
    setItemComMouse(index);
  };

  const handleMouseLeave = () => {
    setItemComMouse(null);
  };

  async function getCart() {
    setLoading(true)
    try {
      const { data } = await api.get("/motorDePontos/carrinho");

      if(data){
        setCarrinhoValido(data.carrinhoValido)
      }
      if(data.produtos) {
        let newData = []
        data.produtos.map(item => 
          newData.push({...item, selected: false, loading: false})  
        )
        setProducts(newData)
        setTotalPontos(data.totalPontos)
        someDiscount(data.produtos)
      }
    } catch (e) {
      getToast({
        messageError: e?.response?.data?.errors[0]?.message,
        status: "error",
        position: 'top-left'
      });
    } finally {
      setLoading(false)
    }
  } 

  async function getPoints() {

    setLoading(true)
    const { data } = await api.get("/motorDePontos/produtos");
    setOriginalProducts(data)
    setLoading(false)
  }

  const addQuantityItemCart = async (quantidade, i, ean, isModal = false, currentErro = false) => {
    const data = products.map((item, index) => {
      if (index === i) {
        return {...item, quantidade: isModal ? quantidade : quantidade + 1, loading: true};
      }
      return item;
    });
    setProducts(data)

    let dataPost = {
      documento: dataUser.documento,
      ean: ean,
      quantidade: isModal ? quantidade : quantidade + 1
    }

    try {
      await api.post('/motorDePontos/carrinho', dataPost);
      sucessoAddToCart(i, quantidade, isModal, currentErro)
    } catch (e) {
      errorAddToCart(i, e.response.data.errors[0].message)
    } 

  }

  const sucessoAddToCart = (i, quantidade, isModal = false, currentErro) => {
    const data = products.map((item, index) => {
      if (index === i) {
        return {...item, loading: false, erro: null, quantidade: isModal ? quantidade : quantidade + 1, pontosTotal: isModal ? quantidade * item.pontosUnitario : (item.pontosTotal ? item.pontosTotal : item.pontos) + item.pontosUnitario, valorDescontoTotal:  isModal ? quantidade * item.valorDescontoUnitario : item.valorDescontoTotal + item.valorDescontoUnitario};
      }
      return item;
    });
    setProducts(data)

    verifyIfCartIsReady(data)

    if(isModal){
      let newTotal = 0
      data.map(item => newTotal = newTotal + item.pontosTotal)
      setTotalPontos(newTotal.toString())
      someDiscount(data)
    }else{
      let newTotal = Number(totalPontos)
      newTotal = newTotal + data[i].pontosUnitario
      setTotalPontos(newTotal.toString())
      someDiscount(data)
    }

    getToast({
      messageError: 'Carrinho atualizado!',
      status: "success",
      position: isMobileSize ? "bottom" : "top-left",
      duration: 1000
    });
  }

  const errorAddToCart = (i, messageError = '') => {
    const data = products.map((item, index) => {
      if (index === i) {
        return {...item, loading: false};
      }
      return item;
    });
    setProducts(data)

    getToast({
      messageError: messageError,
      status: "error",
      position: 'top-left'
    });
  }

  const openEditValueInput = (quantidade, ean, i) => {
    setShowModalInput(true)
    setCurrentIndex(i)
    setCurrentEan(ean)
    setNewValueQuantity(quantidade)
  }

  const verifyIfCartIsReady = (data) => {
    const verify = data.some(array => array.erro != null);
    if(!verify){
      setCarrinhoValido(true)
      setAlertShow(false)
    }
  }

  const excludeProduct = async (ean, i) => {

    const data = products.map((item, index) => {
        if (index === i) {
          return {...item, loading: true};
        }
        return item;
    });
    setProducts(data)

    let dataPost = {
        documento: dataUser.documento,
        ean: ean,
        quantidade: 0
    }

    try {
        await api.post('/motorDePontos/carrinho', dataPost);
        let data = products.filter(item => (item.ean != ean));

        setProducts(data) 
       
        let newTotal = 0
        data.map((item) => newTotal = newTotal + (item.pontosUnitario * item.quantidade))
        setTotalPontos(newTotal.toString())
        someDiscount(data)
        verifyIfCartIsReady(data)
        const newData = data.map((item, index) => {
          if (index === i) {
            return {...item, loading: false};
          }
          return item;
        });

        dispatch({ 
        type: "UPDATE_QUANT_CARRINHO", 
          quantidade: data.length, 
          products: newData
        })

        if(newData.length == 0){
          setQuantItemsCart(0)
          dispatch({ type: "UPDATE_QUANT_CARRINHO", quantidade: 0, products: []})
          setTotalDiscount(0)
        }
        setProducts(newData)

        getToast({
            messageError: 'Carrinho atualizado.',
            status: "success",
            position: isMobileSize ? "bottom" : "top-left",
            duration: 1000
        });

    } catch (e) {
        errorAddToCart(i, e.response.data.errors[0].message)
    } 
    return
  }

  const someDiscount = (data) => {
    const soma = data.reduce((acumulador, item) => {
      return acumulador + item.valorDescontoTotal;
    }, 0);
    setTotalDiscount(soma)
  }

  const ProductsItem = (item, index) => {
    return (
        <div
            key={index}
            style={{
                flexDirection: 'column',
                display: 'flex',
                borderColor: '#e0e0e0',
                borderWidth: 1,
                paddingBottom: 20,
                borderBottom: index+1 == products.length ? isMobileSize ? '1px solid #e0e0e0' : 'none' : '1px solid #e0e0e0' 
            }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
              
              <div style={{ justifyContent: 'center', marginRight: 10,  marginTop: 10}}>
                  <img src={item.imagem } style={{ width: 100, height: 100 }} alt="Product" />
              </div>
              
              <div style={{ flex: 0.8, display: 'flex', marginTop: 10, flexDirection: 'column', marginTop: 20}}>
                 
                  <span style={{ fontSize: 12, fontWeight: '600', color: '#757575'}}>{characterDelimiter(item.nome, 50)}</span>
                  <span style={{ fontSize: 12, fontWeight: '600', color: '#757575' }}>{item.industria}</span>
                  <span style={{ fontSize: 12, fontWeight: '600', color: '#757575'}}>{item.ean}</span>
                  <div>
                    {!item.loading &&
                      <button onClick={() => excludeProduct(item.ean, index)} style={{}}>
                        <span style={{color: '#EC1C24', fontWeight: 'bold', fontSize: 14}}>
                            Excluir do carrinho
                        </span>
                      </button>
                    }
                  </div>
                  {isMobileSize &&
                  <div style={{ flexDirection: 'row', borderRadius: 9999, marginTop: 6, alignItems: 'center', display: 'flex', }}>
                      {item.loading ? 
                          <div style={{ height: 26.5 }}>
                              <Spinner color="#EC1C24" size={'sm'} />
                          </div>
                      :
                        <select name="quantidade" onChange={(e) => { 
                            if(e.target.value == 9999){
                              return openEditValueInput(item.quantidade, item.ean, index)
                            }
                            addQuantityItemCart(Number(e.target.value), index, item.ean, true, item.erro) }
                          } value={item.quantidade} id="lang" className="select-quantity">
                          {Array.from({length: 10}, (item2, index) => 
                            <option value={index+1}>{index < 9 ? '0' : ''}{index + 1}</option>
                          )}
                          {item.quantidade > 10 && 
                            <option value={item.quantidade}>{item.quantidade}</option>
                          }
                          <option value={9999}>10+</option>
                        </select>
                      }
                      <span style={{fontSize: 14, fontWeight: 700, marginLeft: 10, color: '#616161'}}>Unidades</span>
                  </div>
                  }
              </div>
              {!isMobileSize &&
                <>
                  <div style={{flexDirection: 'row', display: 'flex', flex: 1, marginLeft: 15, marginTop: 20}}>
                    <div className="title-column" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <div>
                          {item.loading ? 
                            <div style={{ height: 26.5 }}>
                              <Spinner color="#EC1C24" size={'sm'} />
                            </div>
                          :
                            <select name="quantidade" onChange={(e) => { 
                                if(e.target.value == 9999){
                                  return openEditValueInput(item.quantidade, item.ean, index)
                                }
                                addQuantityItemCart(Number(e.target.value), index, item.ean, true, item.erro) }
                              } value={item.quantidade} id="lang" className="select-quantity">
                              {Array.from({length: 10}, (item2, index) => 
                                <option value={index+1}>{index < 9 ? '0' : ''}{index + 1}</option>
                              )}
                              {item.quantidade > 10 && 
                                <option value={item.quantidade}>{item.quantidade}</option>
                              }
                              <option value={9999}>10+</option>
                            </select>
                          }
                      </div>
                    </div>
                    <div className="title-column" style={{width: '100%', display: 'flex', justifyContent: 'center', marginRight: 48}}>
                      <span className="font-20">{formattingNumberWithDecimals(item.valorDescontoUnitario).replace('.', ',')}</span>
                    </div>

                    <div className="title-column" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: -20}}>
                      <span className="font-20">{formattingNumberWithDecimals(item.valorDescontoTotal).replace('.', ',')}</span>
                    </div>
                    <div className="title-column" style={{width: '110%', display: 'flex', justifyContent: 'flex-end', paddingLeft: 20}}>
                      <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                        {!isMobileSize && item.erro &&
                            <img src={warning} style={{width: 35, height: 35, marginTop: -6}} className={"img-alert"} onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave} /> 
                        }
                        <span className="font-20" style={{color: item.erro ? "#EC1C24" : "#616161"}}>{item.pontosTotal}</span>
                      </div>
                      {itemComMouse === index && 
                        <div style={{position: 'absolute', borderRadius: 12, padding: 15, right: 0, top: -20, background: '#EC1C24', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <span style={{ fontSize: 12, fontWeight: '600', color: '#fff', textAlign: 'center'}}>{item.erro}</span>
                        </div>
                      }
                    </div>
                  </div>
                </>
              }
          </div>
          {isMobileSize &&
            <div className="atributes-product-container" style={{marginLeft: -10, marginTop: 20}}>
              <div className="item-atribute">
                <span>
                  Desconto unitário
                </span>
                <span className="value">
                  {formattingNumberWithDecimals(item.valorDescontoUnitario).replace('.', ',')}
                </span>
              </div>
              <div className="item-atribute">
                <span>
                  Desconto total
                </span>
                <span className="value">
                  {formattingNumberWithDecimals(item.valorDescontoTotal).replace('.', ',')}
                </span>
              </div>
             
              <div className="item-atribute" style={{position: 'relative'}}>
                <span>
                  Pontos
                </span>
                <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                  {isMobileSize && item.erro &&
                    <img src={warning} style={{width: 35, height: 35, marginTop: -6}} className={"img-alert"} onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave} /> 
                  }
                  <span className="value" style={{color: item.erro ? "#EC1C24" : "#616161"}}>
                    {item.pontosTotal}
                  </span>
                </div>
                {itemComMouse === index && 
                  <div style={{position: 'absolute', width: 300, borderRadius: 12, padding: 15, right: -20, top: -50, background: '#EC1C24', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span style={{ fontSize: 12, fontWeight: '600', color: '#fff', textAlign: 'center'}}>{item.erro}</span>
                  </div>
                }
              </div>
            </div>
          }
        </div>
    );
  };

  const confirmActiveDiscount = async () => {
   
    if(!carrinhoValido) {
      getToast({
          messageError: errorMessage,
          status: "error",
          position: isMobileSize ? "bottom" : "top-left"
      });
      return 
    }

    setLoadingCheckout(true)
    setAlertShow(false)
    setAlertTitle('')
    setAlertSubtitle('')

    try {
      await api.get('/motorDePontos/ativarCarrinho');

      getToast({
          messageError: `Seu desconto foi ativado com sucesso!`,
          status: "success",
          position: isMobileSize ? "bottom" : "top-left"
      });

      setProducts([])
      dispatch({ 
          type: "UPDATE_QUANT_CARRINHO", 
          quantidade: 0, 
          products: []
      })

      dispatchActivated({ type: "UPDATE_QUANT_ACTIVATED", quantidade: stateActivated.quantidade + products.length})

      navigate('/ativos')

    } catch (e) {
      getToast({
          messageError: e?.response?.data?.errors[0]?.message,
          status: "error",
          position: isMobileSize ? "bottom" : "top-left"
      });
      setAlertShow(true)
      setAlertTitle(e.response.data.errors[0].message)
      setAlertSubtitle('')

      setTimeout(() => {
        getCart()
      }, 1000);

    } finally {
      setLoadingCheckout(false)
    }
  }

  return (
    <>
    {!isMobileSize &&
      <Flex
          width="100%"
          maxWidth="1420px"
          margin="0px auto"
          justifyContent="space-between"
          alignItems="baseline"
          flexDirection={ isMobileSize ? 'column' : 'row' }
          marginTop={8}
        >
          <div style={{paddingLeft: isMobileSize ? 10 : 0}}>
            <Breadcrumb_v2
              uri={'carrinho'}
              categoriaSelecionadaNome={'Carrinho'}
              categoriaSelecionadaPai={''}
            />
          </div>
      </Flex>
    }
    
    <Flex
      width="100%"
      maxWidth="1400px"
      paddingBottom={100}
      margin={isMobileSize ? "30px auto 79px auto" : "0px auto 0px auto"}
    >
      <div className="container-cart">
        <div className="container-cart-2" style={{flex: 1, margin: 0}}>
        {alertShow &&
          <Flex
            width="100%"
            maxWidth="1400px"
            paddingBottom={6}
            
            >
              <div className="container-cart">
                <div className="container-cart-items" style={{marginLeft: -30}}>
                  <div className="title-cart"  style={{border: 'none'}}>
                    <span style={{color: '#EC1C24'}}>
                      Atenção
                    </span>
                  </div>
                  <div style={{padding: 30}}>
                    <div className="container-alert">
                        <div className="icon">
                          <img src={warning} />
                        </div>
                        <div className="text-alert">
                          <span className="title">
                            {alertTitle}
                          </span>
                          <span className="sub-title">
                            {alertSubtitle}
                          </span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </Flex>
          }
          <div className="container-cart-items" style={{border:  '2px solid #e0e0e0'}}>
            <div className="title-cart" style={{borderBottom: isMobileSize ? 'none' : '2px solid #e0e0e0'}}>
              <span style={{fontSize: isMobileSize ? 16 : 30}}>
                Carrinho
              </span>
            </div>
            <div className="list-products">

              {products.length == 0 && !loading &&
                <div style={{width: '100%', padding: 10,  flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <span style={{fontSize: 30, color:  '#344054', textAlign: 'center', fontWeight: '500'}}>
                    Seu carrinho está vázio! <br/><span style={{fontSize: 20, color:  'gray', textAlign: 'center', fontWeight: '500'}}>Adicione produtos abaixo.</span>
                  </span>
                  <img src={empty} style={{height: 100, width: 100, marginTop: 30}} />
                  <button onClick={() => {  navigate('/resgate') }} style={{height: 50, cursor: 'pointer', marginTop: 40, paddingLeft: 25, paddingRight: 25, backgroundColor: '#EC1C24', borderRadius: 999, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{color: '#fff', fontSize: 20, fontWeight: '600'}}>
                      Escolher produtos
                    </span>
                  </button>
                  <br/>
                  <br/>
                </div>
              }

              {loading &&
                <Flex padding={1} borderRadius={8} width={'100%'} height={300} flexDirection="column" alignItems={"center"} justifyContent={"center"}>
                  <Spinner color="#EC1C24" size={"lg"} style={{}} />
                </Flex>
              }
              {products.length > 0 && !loading &&
                <>
                  {!isMobileSize &&
                    <div className="title-list-products">
                      <div className="left-title" style={{flex: 3}}>
                        <span>Produtos</span>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div className="title-column">
                          <span>Unidades</span>
                        </div>
                        <div className="title-column">
                          <span>Desconto unitário</span>
                        </div>
                        <div className="title-column">
                          <span>Desconto total</span>
                        </div>
                        <div className="title-column" style={{marginRight: 0}}>
                          <span>Pontos</span>
                        </div>
                      </div>
                    </div>
                  }
                  {products &&
                      products.map((item, index) => {
                          return (
                              ProductsItem(item, index)
                          )
                      })
                  }
                </>
              }
              {!isMobileSize &&
                <div style={{height: 50}} />
              }

            </div>
          </div>
        </div>
        <div className="container-summary-items">
          <div className="container-summary">
            {!isMobileSize &&
              <div className="title-summary">
                <span>
                  Resumo
                </span>
              </div>
            }
            <div className="list-summary">
              {isMobileSize &&
                <span className="text-resum">
                  Resumo
                </span>
              }
              <div className="title-list-summary">
                <div className="left-title">
                  <span>Produtos ({products.length})</span>
                </div>
                <div className="right-title">
                  <span>
                    {totalPontos} pontos
                  </span>
                </div>
              </div>
              <div className="title-list-summary" style={{marginTop: 15}}>
                <div className="left-title">
                  <span>Total de descontos</span>
                </div>
                <div className="right-title">
                  <span>
                    {formattingNumberWithDecimals(totalDiscount).replace('.', ',')}
                  </span>
                </div>
              </div>

              {products.length > 0 && (
                  <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <button onClick={() => confirmActiveDiscount()} disabled={loadingCheckout || !carrinhoValido} style={{height: 40, opacity: !carrinhoValido ? 0.4: 1, width: '100%', borderRadius: 10, backgroundColor: '#10125A', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 40}}>
                          <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              {loadingCheckout ?
                                <Spinner color="white" />
                                :
                                <span style={{fontSize: 18, fontWeight: '600', color: '#fff'}}>
                                  Ativar desconto
                                </span>
                              }
                          </div>
                      </button>
                  </div>
              )}

            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModalInput}
        onClose={() => setShowModalInput(false)}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent maxWidth={isMobileSize ? "90%" : '40%'}>
            <ModalHeader padding={7}>
                Digite a quantidade desejada
                <span style={{ fontSize: 12, color: 'rgba(0,0,0,.55)' }}>
                    <br />
                    Colocando 0 excluirá o produto do carrinho
                </span>
            </ModalHeader>
                <ModalCloseButton _focus={{border: 'none'}} />
            <ModalBody pb={6}>
                <span style={{ fontSize: 15, marginLeft: 5, fontWeight: 500, color: 'gray' }} >
                Quantidade de produtos
                </span>
                <Flex padding={1} borderRadius={8} width={300} flexDirection="column">
                <Input
                    type='number'
                    autoFocus
                    style={{
                        width: 250,
                        paddingLeft: 10,
                        padding: 10,
                        border: '1px solid black',
                        borderRadius: 5,
                        fontSize: 16
                    }}
                    placeholder='Digite a quantidade'
                    onChange={(e) => setNewValueQuantity(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                          if(!newValueQuantity) return
                          if(newValueQuantity == '0'){
                              setShowModalInput(false)
                              return excludeProduct(currentEan, currentIndex)
                          }
                          addQuantityItemCart(Number(newValueQuantity), currentIndex, currentEan, true, currentErro)
                          
                          setShowModalInput(false)
                      }
                    }}
                    value={newValueQuantity}
                />
                </Flex>
            </ModalBody>
            <Flex alignItems={'flex-end'} justifyContent={'flex-end'} padding={5}>
              <Button
                colorScheme="red"
                mr={3}
                onClick={() => {
                    if(!newValueQuantity) return
                    if(newValueQuantity == '0'){
                        setShowModalInput(false)
                        return excludeProduct(currentEan, currentIndex)
                    }
                    addQuantityItemCart(Number(newValueQuantity), currentIndex, currentEan, true, currentErro)
                    setShowModalInput(false)
                }}
                disabled={loading}
                minWidth={100}
              >
              {loading ?
                <Spinner color="white" />
                :
                'Confirmar'
              }
              </Button>
            </Flex>
        </ModalContent>
      </Modal>
    </Flex>
    </>
  );
};

export default CarrinhoPage;
