import { useState } from "react";
import {
  useDisclosure,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { MenuCategoriesMobile } from "./MenuCategoriesMobile";
import { ISubCategory } from "interfaces/categories/ICategory";

interface SubCategoryProps extends ISubCategory {
  categoriaId?: number;
  id?: number;
  descricao: string;
  uri: string;
}

interface MenuHeaderMobileProps {
  // subCategories: SubCategoryProps[];
  handleShowMenuList: (categoryId: number) => void;
  categoryIdRef: any;
  categoryNameRef: any;
  subCategories: ISubCategory[];
  categoriaSelecionada: any;
}

export const MenuHeaderMobile = ({
  subCategories,
  handleShowMenuList,
  categoryIdRef,
  categoryNameRef,
  categoriaSelecionada
}: MenuHeaderMobileProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isArrowDown, setIsArrowDown] = useState(false);

  const arrowIcon = isArrowDown ? (
    <FiChevronUp fontSize={30} />
  ) : (
    <FiChevronDown fontSize={30} />
  );

  return (
    <>
      <Button
        as={IconButton}
        icon={<HamburgerIcon fontSize={30} />}
        border="1px solid transparent"
        onClose={onClose}
        onClick={onOpen}
        isOpen={isOpen}
        width="30px"
        height="20px"
        background="transparent"
        _hover={{ background: "transparent" }}
        _active={{ background: "transparent" }}
        _focus={{ background: "transparent" }}
      />
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody width="100%" minWidth="319px" overflowX="hidden" marginTop={8}>
            <MenuCategoriesMobile
              handleCloseMenu={onClose}
              arrowIcon={arrowIcon}
              setIsArrowDown={setIsArrowDown}
              isArrowDown={isArrowDown}
              categoryIdRef={categoryIdRef}
              handleShowMenuList={handleShowMenuList}
              subCategories={subCategories}
              categoryRefName={categoryNameRef}
              categoriaSelecionada={categoriaSelecionada}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
